import React from 'react';
import PropTypes from 'prop-types';

function Loader(props) {
    return (
        <React.Fragment>
            <div className="loader-wrapper">
                <div className="loader" />
            </div>
        </React.Fragment>
    );
}

Loader.propTypes = {
    text: PropTypes.string,
};

Loader.defaultProps = {
    text: 'Loading',
};

export default Loader;
