import React, { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Col, Row } from 'reactstrap';
import { Layout } from 'containers';
import { Loader, Modals } from 'components';
import { UsersProvider } from 'providers';

const UserRoles = () => {
	const [ roles, setRoles ] = useState([]);
	const [ pageLoading, setPageLoading ] = useState(true);
	const [ showUpdateModal, setShowUpdateModal ] = useState(false);
	const [ selectedRole, setSelectedRole ] = useState({});

	useEffect(() => {
		const fetchData = async () => {
			const roles = await UsersProvider.roles();
			setRoles(roles.results);
			setPageLoading(false);
		};
		fetchData();
	}, [setPageLoading, setRoles]);

	const handlePromptEditRole = role => {
		setSelectedRole(role);
		setShowUpdateModal(true);
	};

	const handleDeleteRole = async (id) => {
		confirmAlert({
			title: 'Confirm Delete',
			message: 'Are you sure to delete selected role?',
			buttons: [
				{
					label: 'Yes',
					onClick: async () => {
						const result = await UsersProvider.deleteRole(id);
						if (result.status === 'success') {
							alert('User Role Deleted!');
							window.location = '/users/roles';
						} else {
							alert(`Error: ${result.message}`);
						}
					}
				},
				{
				  	label: 'No',
				}
			]
		});
	};

	const handleEditRole = async (id, data) => {
		const result = await UsersProvider.updateRole(id, data);
		if (result.status === 'success') {
			alert('User Role Updated!');
			window.location = '/users/roles';
		} else {
			alert(`Error: ${result.message}`);
		}
	};

	return (
		<Layout header={{ active: 'users' }}>
			<Modals.EditRole
				show={showUpdateModal}
				setShow={setShowUpdateModal}
				role={selectedRole}
				callback={handleEditRole}
			/>
			{pageLoading && <Loader />}
			<Row className="pt-4">
				<Col sm={12}>
					<div className="element-wrapper">
						<h6 className="element-header">User Role Management</h6>
						<div className="element-content">
							<div className="tablo-with-chart">
								<Row>
									<Col sm={12}>
										<div className="element-wrapper">
											<div className="element-box-tp">
												<div className="table-responsive">
													<table className="table table-padded">
														<thead>
															<tr>
																<th>Name</th>
																<th>Description</th>
																<th className="text-right">Actions</th>
															</tr>
														</thead>
														<tbody>
															{roles.length > 0 && roles.map(item => (
																<tr key={item.id}>
																	<td><div className="user-with-avatar"><span>{item.name}</span></div></td>
																	<td><div className="smaller lighter">{item.description}</div></td>
																	<td className="row-actions text-right">
																		<a
																			href={`/users/roles`}
																			onClick={e => {
																				e.preventDefault();
																				handlePromptEditRole(item);
																			}}
																		>
																			<i className="os-icon os-icon-edit-32"></i>
																		</a>
																		<a
																			className="danger" href={`/users/roles`}
																			onClick={e => {
																				e.preventDefault();
																				handleDeleteRole(item.id);
																			}}
																		>
																			<i className="os-icon os-icon-ui-15"></i>
																		</a>
																	</td>
																</tr>
															))}
														</tbody>
													</table>
												</div>
											</div>
										</div>
									</Col>
								</Row>
							</div>
						</div>
					</div>
				</Col>
			</Row>
		</Layout>
	);
};

export default UserRoles;
