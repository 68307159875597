import axios from 'axios';
import config from '../config';

const baseurl = config.api[config.stage].baseurl;

class ModelsProvider {
    static async getAll(query = '') {
        const url = `${baseurl}models${query}`;
        return await axios({
            url,
            method: 'GET',
            crossdomain: true,
            mode: 'no-cors',
        })
        .then((json) => json.data)
        .then((res) => res)
        .catch((err) => {
          if (err.response) return err.response.data;
          return err;
        });
    }
}

export default ModelsProvider;
