import axios from 'axios';
import config from '../config';
import SessionProvider from './SessionProvider';

const baseurl = config.api[config.stage].baseurl;
const session = SessionProvider.get();
const token = SessionProvider.getToken();
let headers = {};

if (session.isValid) {
  headers = {
    'Authorization': `Bearer ${token}`,
  }
}

class UsersProvider {
  static async getAll() {
    return await axios({
      mode: 'no-cors',
      method: 'GET',
      url: `${baseurl}users`,
      crossdomain: true,
      headers,
    })
    .then((json) => json.data)
    .then(res => res)
    .catch((err) => {
      if (err.response) return err.response.data;
      return err;
    });
  };

  static async create(data) {
    return await axios({
      mode: 'no-cors',
      method: 'POST',
      url: `${baseurl}users`,
      crossdomain: true,
      headers,
      data,
    })
    .then((json) => json.data)
    .then(res => res)
    .catch((err) => {
      if (err.response) return err.response.data;
      return err;
    });
  };

  static async update(id, data) {
    return await axios({
      mode: 'no-cors',
      method: 'PUT',
      url: `${baseurl}users/${id}`,
      crossdomain: true,
      headers,
      data,
    })
    .then((json) => json.data)
    .then(res => res)
    .catch((err) => {
      if (err.response) return err.response.data;
      return err;
    });
  };

  static async delete(id) {
    return await axios({
      mode: 'no-cors',
      method: 'DELETE',
      url: `${baseurl}users/${id}`,
      crossdomain: true,
      headers,
    })
    .then((json) => json.data)
    .then(res => res)
    .catch((err) => {
      if (err.response) return err.response.data;
      return err;
    });
  };

  static async roles() {
    return await axios({
      mode: 'no-cors',
      method: 'GET',
      url: `${baseurl}roles`,
      crossdomain: true,
      headers,
    })
    .then((json) => json.data)
    .then(res => res)
    .catch((err) => {
      if (err.response) return err.response.data;
      return err;
    });
  };

  static async addRole(data) {
    return await axios({
      mode: 'no-cors',
      method: 'POST',
      url: `${baseurl}roles`,
      crossdomain: true,
      headers,
      data,
    })
    .then((json) => json.data)
    .then(res => res)
    .catch((err) => {
      if (err.response) return err.response.data;
      return err;
    });
  };

  static async updateRole(id, data) {
    return await axios({
      mode: 'no-cors',
      method: 'PUT',
      url: `${baseurl}roles/${id}`,
      crossdomain: true,
      headers,
      data,
    })
    .then((json) => json.data)
    .then(res => res)
    .catch((err) => {
      if (err.response) return err.response.data;
      return err;
    });
  };

  static async deleteRole(id) {
    return await axios({
      mode: 'no-cors',
      method: 'DELETE',
      url: `${baseurl}roles/${id}`,
      crossdomain: true,
      headers,
    })
    .then((json) => json.data)
    .then(res => res)
    .catch((err) => {
      if (err.response) return err.response.data;
      return err;
    });
  };
}

export default UsersProvider;
