import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { Layout } from 'containers';
import { Breadcrumb, FeatherIcon, Loader } from 'components';
import { ModelsProvider } from 'providers';

const Models = () => {
    const [ pageLoading, setPageLoading ] = useState(true);
    const [ filteredModels, setFilteredModels ] = useState([]);
    const [ models, setModels ] = useState([]);

	useEffect(() => {
		const fetchData = async () => {
            const models = await ModelsProvider.getAll();
            setFilteredModels(models.results);
            setModels(models.results);
            setPageLoading(false);
		};
		fetchData();
    }, [ setFilteredModels, setModels, setPageLoading ]);

    const handleFilter = val => {
        const filtered = models.filter(item => item.name.toLowerCase().indexOf(val.toLowerCase()) !== -1);
        setFilteredModels(filtered);
    };

    return (
        <Layout>
			{pageLoading && <Loader />}
			<Breadcrumb
                title="Manage Models"
			/>
            <Row className="inbox-wrapper">
                <Col lg={12}>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col lg={12} className="email-content">
                                    <div className="email-inbox-header">
                                        <Row className="row align-items-center">
                                            <Col lg={6}>
                                                <div className="email-title mb-2 mb-md-0">
                                                    <span className="icon">
                                                        <FeatherIcon icon="database" />
                                                    </span> Vehicle Models
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <div className="email-search">
                                                    <div className="input-group input-search">
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            placeholder="Search models..."
                                                            onKeyUp={e => handleFilter(e.target.value)}
                                                        />
                                                        <span className="input-group-btn">
                                                            <button className="btn btn-outline-secondary" type="button">
                                                                <FeatherIcon icon="search" />
                                                            </button>
                                                        </span>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div>
                                        {filteredModels.length > 0 && filteredModels.map((item, idx) => (
                                        <a
                                            key={item.id}
                                            href={`/vehicles/models/${item.id}`}
                                            className={`d-flex align-items-center border-bottom ${idx === 0 ? 'pb-3' : 'py-3'}`}
                                            onClick={e => e.preventDefault()}
                                        >
                                            <div className="mr-3">
                                                <img src={require("images/faces/face2.jpg")} className="rounded-circle wd-35" alt={item.name} />
                                            </div>
                                            <div className="w-100">
                                                <div className="d-flex justify-content-between">
                                                    <h6 className="text-body mb-2">{item.name}</h6>
                                                    {/* <p className="text-muted tx-12">{item.manufacturer.description}</p> */}
                                                </div>
                                                <p className="text-muted tx-13">{item.manufacturer.description}</p>
                                            </div>
                                        </a>
                                        ))}
                                        {filteredModels.length === 0 && <div className="text-center">No match for specified filter</div>}
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Layout>
    );
};

export default Models;
