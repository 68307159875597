import axios from 'axios';
import config from '../config';
import SessionProvider from './SessionProvider';

const baseurl = config.api[config.stage].baseurl;
const session = SessionProvider.get();
const token = SessionProvider.getToken();
let headers = {};

if (session.isValid) {
  headers = {
    'Authorization': `Bearer ${token}`,
  }
}

class VehiclesProvider {
    static async create(data) {
        const url = `${baseurl}vehicles`;
        return await axios({
            url,
            method: 'POST',
            crossdomain: true,
            mode: 'no-cors',
            headers,
            data,
        })
        .then((json) => json.data)
        .then((res) => res)
        .catch((err) => {
          if (err.response) return err.response.data;
          return err;
        });
    }

    static async import(data) {
        const url = `${baseurl}vehicles/import`;
        return await axios({
            url,
            method: 'POST',
            crossdomain: true,
            mode: 'no-cors',
            headers,
            data,
        })
        .then((json) => json.data)
        .then((res) => res)
        .catch((err) => {
          if (err.response) return err.response.data;
          return err;
        });
    }

    static async get(id) {
        const url = `${baseurl}vehicles/${id}`;
        return await axios({
            url,
            method: 'GET',
            crossdomain: true,
            mode: 'no-cors',
        })
        .then((json) => json.data)
        .then((res) => res.data)
        .catch((err) => {
          if (err.response) return err.response.data;
          return err;
        });
    }

    static async getByPermakey(permakey) {
        const url = `${baseurl}vehicles/permakey/${permakey}`;
        return await axios({
            url,
            method: 'GET',
            crossdomain: true,
            mode: 'no-cors',
        })
        .then((json) => json.data)
        .then((res) => res.data)
        .catch((err) => {
          if (err.response) return err.response.data;
          return err;
        });
    }

    static async getAll(offset=0, limit=100) {
        const url = `${baseurl}vehicles?offset=${offset}&limit=${limit}`;
        return await axios({
            url,
            method: 'GET',
            crossdomain: true,
            mode: 'no-cors',
        })
        .then((json) => json.data)
        .then((res) => res)
        .catch((err) => {
          if (err.response) return err.response.data;
          return err;
        });
    }

    static async getByTag(tag) {
        const url = `${baseurl}vehicles/tag/${tag}`;
        return await axios({
            url,
            method: 'GET',
            crossdomain: true,
            mode: 'no-cors',
        })
        .then((json) => json.data)
        .then((res) => res)
        .catch((err) => {
          if (err.response) return err.response.data;
          return err;
        });
    }

    static async getSpecials() {
        const url = `${baseurl}specials`;
        return await axios({
            url,
            method: 'GET',
            crossdomain: true,
            mode: 'no-cors',
        })
        .then((json) => json.data)
        .then((res) => res)
        .catch((err) => {
          if (err.response) return err.response.data;
          return err;
        });
    }

    static async types() {
        const url = `${baseurl}vehicles/types`;
        return await axios({
            url,
            method: 'GET',
            crossdomain: true,
            mode: 'no-cors',
        })
        .then((json) => json.data)
        .then((res) => res)
        .catch((err) => {
          if (err.response) return err.response.data;
          return err;
        });
    }
}

export default VehiclesProvider;
