import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { Layout } from 'containers';
import { AddVehicleWizard, Breadcrumb, Loader, Modals } from 'components';
import { VehiclesProvider } from 'providers';

const AddVehicle = () => {
    const [ pageLoading, setPageLoading ] = useState(true);
    const [ showResultsModal, setShowResultsModal ] = useState(false);
    const [ resultsData, setResultsData ] = useState({});
    const [ activeTab, setActiveTab ] = useState(0);
    const [ updatedData, setUpdatedData ] = useState({});

	useEffect(() => {
		const fetchData = async () => {
            setPageLoading(false);
		};
		fetchData();
    }, [ setPageLoading ]);

    const getVehicleName = data => {
        const { registration_year, make, model, variant } = data;
        const name = `${registration_year} ${make} ${model} ${variant}`;
        return name.trim();
    };

    const getVehicleImageDir = data => {
        const { make } = data;
        let dir = `vehicles/${make.toLowerCase()}/`;
        let permakey = getVehicleName(data);
        permakey = permakey.split(' ').join('-').trim();
        permakey = (permakey[0] === '-') ? permakey.substr(1) : permakey;
        permakey = permakey.toLowerCase().replace(`${make.toLowerCase()}-`, '');
        dir     += `${permakey}/`;
        return dir;
    };

    const generatePermakey = data => {
        let permakey = getVehicleName(data);
        permakey = permakey.split(' ').join('-').trim();
        permakey = (permakey[0] === '-') ? permakey.substr(1) : permakey;
        return permakey.toLowerCase();
    };

    const handleSaveProceed = async (step, key = null, data = null) => {
        let obj = updatedData;
        if (key && data) {
            obj[key] = data;
            setUpdatedData(obj);
        }
        if (step === 2) {
            setPageLoading(true);
            const { attributes, location, additional } = obj;
            const postData = {
                model_id: attributes.model_id,
                registration_year: attributes.registration_year,
                registration_number: attributes.registration_number,
                price: attributes.price,
                current_mileage: attributes.current_mileage,
                description: additional.description,
                name: getVehicleName(attributes),
                colour: attributes.colour,
                vin_number: attributes.vin_number,
                permakey: generatePermakey(attributes),
                video_link: attributes.video_link,
                vehicle_status: attributes.vehicle_status,
                stock_number: attributes.stock_number,
                regular_price: attributes.regular_price,
                label: attributes.label,
                city_mpg: attributes.city_mpg,
                highway_mpg: attributes.highway_mpg,
                interior_colour: attributes.interior_colour,
                tax_label: attributes.tax_label,
                fuel_type: attributes.fuel_type,
                fuel_economy: attributes.fuel_economy,
                body_style: attributes.body_style,
                location: location.location,
                variant: attributes.variant,
                drivetrain: attributes.drivetrain,
                engine: attributes.engine,
                transmission: attributes.transmission,
                trim: attributes.trim,
                image_url: attributes.image_url,
                image_dir: getVehicleImageDir(attributes),
            };
            const result = await VehiclesProvider.create(postData);
            setPageLoading(false);
            if (result.status === 'success') {
                setResultsData({
                    title: 'Vehicle Added!',
                    titleClass: 'text-success',
                    body: 'New vehicle information successfully saved.',
                    bodyClass: 'text-success',
                });
                return setShowResultsModal(true);
            } else {
                setResultsData({
                    title: 'Error!',
                    titleClass: 'text-danger',
                    body: result.message,
                    bodyClass: 'text-danger',
                });
                return setShowResultsModal(true);
            }
        }
        return setActiveTab(step + 1);
    };

    return (
        <Layout>
			{pageLoading && <Loader />}
            <Breadcrumb
                title="Add Vehicle"
            />
            <Modals.Results
                show={showResultsModal}
                setShow={setShowResultsModal}
                data={resultsData}
                onClose={() => window.location = '/vehicles/add'}
            />
            <Row>
                <Col md={12} className="stretch-card">
                    <Card>
                        <CardBody>
                            <Row>
                                <Col lg={12}>
                                    <h4 className="card-title">Capture Vehicle Information</h4>
                                    <div role="application" className="wizard clearfix vertical">
                                        <div className="steps clearfix">
                                            <ul role="tablist">
                                                <li role="tab" className={`first ${activeTab === 0 ? 'current' : 'done'}`} aria-disabled={false} aria-selected={activeTab === 0}>
                                                    <a id="wizardVertical-t-0" href="#wizardVertical-h-0" aria-controls="wizardVertical-p-0"><span className="current-info audible">current step: </span><span className="number">1.</span> Vehicle Attributes</a>
                                                </li>
                                                <li role="tab" className={`${activeTab === 1 ? 'current' : ''} ${activeTab < 1 ? 'disabled' : ''} ${activeTab > 1 ? 'done' : ''}`} aria-disabled={activeTab < 1} aria-selected={activeTab === 1}>
                                                    <a id="wizardVertical-t-1" href="#wizardVertical-h-1" aria-controls="wizardVertical-p-1"><span className="number">2.</span> Vehicle Location</a>
                                                </li>
                                                <li role="tab" className={`${activeTab === 2 ? 'current' : 'disabled'} last`} aria-disabled={activeTab < 2} aria-selected={activeTab === 2}>
                                                    <a id="wizardVertical-t-2" href="#wizardVertical-h-2" aria-controls="wizardVertical-p-2"><span className="number">3.</span> Additional Information</a>
                                                </li>
                                            </ul>
                                        </div>
                                        {activeTab === 0 &&
                                        <AddVehicleWizard.VehicleAttributes
                                            handleSaveProceed={handleSaveProceed}
                                            activeTab={activeTab}
                                            setActiveTab={setActiveTab}
                                            setPageLoading={setPageLoading}
                                        />}
                                        {activeTab === 1 &&
                                        <AddVehicleWizard.VehicleLocation
                                            handleSaveProceed={handleSaveProceed}
                                            activeTab={activeTab}
                                            setActiveTab={setActiveTab}
                                        />}
                                        {activeTab === 2 &&
                                        <AddVehicleWizard.AdditionalInformation
                                            handleSaveProceed={handleSaveProceed}
                                            activeTab={activeTab}
                                            setActiveTab={setActiveTab}
                                            setPageLoading={setPageLoading}
                                        />}
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Layout>
    );
};

export default AddVehicle;
