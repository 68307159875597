import React, { useState } from 'react';
import useForm from 'react-hook-form';
import { Col, Row } from 'reactstrap';
import SimpleMDE from 'react-simplemde-editor';
import 'easymde/dist/easymde.min.css';

const AdditionalInformation = props => {
    const {
        activeTab,
        handleSaveProceed,
        setActiveTab,
        setPageLoading,
    } = props;
    const [ mdeValue, setMdeValue ] = useState(null);
    const { handleSubmit } = useForm();

    const onSubmit = data => {
        setPageLoading(true);
        let post = data;
        post.description = mdeValue;
        handleSaveProceed(activeTab, 'additional', post);
    };

    const handleDescriptionChange = value => {
        setMdeValue(value);
    };

    return (
        <>
            <div className="content clearfix">
                <h2 id="wizardVertical-h-4" tabIndex="-1" className="title current">Additional Information</h2>
                <section id="wizardVertical-p-4" role="tabpanel" aria-labelledby="wizardVertical-h-4" className="body current" aria-hidden="false">
                    <h4>Additional Information</h4>
                    <p className="card-description">
                        Provide any additional vehicle information vehicle location below.
                    </p>
                    <form id="signupForm" className="cmxform" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col sm={12}>
                                <div className="form-group">
                                    <label>Vehice Overview</label>
                                    <SimpleMDE onChange={handleDescriptionChange} />
                                </div>
                            </Col>
                        </Row>
                        {/* <Row>
                            <Col sm={12}>
                                <div className="form-group">
                                    <label>Vehice Features</label>
                                    <div className="form-check">
                                        <label className="form-check-label">
                                            <input type="checkbox" className="form-check-input" />
                                            Feature #1
                                            <i className="input-frame" />
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <label className="form-check-label">
                                            <input type="checkbox" className="form-check-input" />
                                            Feature #2
                                            <i className="input-frame" />
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <label className="form-check-label">
                                            <input type="checkbox" className="form-check-input" />
                                            Feature #3
                                            <i className="input-frame" />
                                        </label>
                                    </div>
                                </div>
                            </Col>
                        </Row> */}
                    </form>
                </section>
            </div>
            <div className="actions clearfix">
                <ul role="menu" aria-label="Pagination">
                    <li className={activeTab === 0 ? 'disabled' : ''} aria-disabled={activeTab === 0}>
                        <a
                            href="#previous"
                            role="menuitem"
                            onClick={e => {
                                e.preventDefault();
                                if (activeTab > 0) {
                                    return setActiveTab(activeTab - 1);
                                }
                            }}
                        >Previous</a>
                    </li>
                    {activeTab === 2 &&
                    <li aria-hidden="true">
                        <button
                            type="submit"
                            form="signupForm"
                            className="btn btn-primary"
                            onClick={handleSubmit(onSubmit)}
                        >
                            Finish
                        </button>
                    </li>}
                </ul>
            </div>
        </>
    );
};

export default AdditionalInformation;
