import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { Layout } from 'containers';
import { Breadcrumb, FeatherIcon, Loader } from 'components';
import { BookingsProvider } from 'providers';

const ServiceBookings = () => {
    const [ pageLoading, setPageLoading ] = useState(true);
    const [ bookings, setBookings ] = useState([]);

	useEffect(() => {
		const fetchData = async () => {
            const bookings = await BookingsProvider.getAll();
            setBookings(bookings.results);
            setPageLoading(false);
		};
		fetchData();
    }, [ setBookings, setPageLoading ]);

    return (
        <Layout>
			{pageLoading && <Loader />}
			<Breadcrumb
                title="Service Bookings"
			/>
            <Row className="inbox-wrapper">
                <Col lg={12}>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col lg={12} className="email-content">
                                    <div className="email-inbox-header">
                                        <Row className="row align-items-center">
                                            <Col lg={6}>
                                                <div className="email-title mb-2 mb-md-0">
                                                    <span className="icon">
                                                        <FeatherIcon icon="database" />
                                                    </span> Service Bookings&nbsp;
                                                    {/* <span className="new-messages">(2 new bookings)</span> */}
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <div className="email-search">
                                                    <div className="input-group input-search">
                                                        <input className="form-control" type="text" placeholder="Search bookings..." />
                                                        <span className="input-group-btn">
                                                            <button className="btn btn-outline-secondary" type="button">
                                                                <FeatherIcon icon="search" />
                                                            </button>
                                                        </span>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
							<div className="table-responsive">
								<table className="table table-hover mb-0">
									<thead>
										<tr>
											<th className="pt-0">#</th>
											<th className="pt-0">Customer</th>
											<th className="pt-0">Vehicle</th>
											<th className="pt-0">Dealership</th>
											<th className="pt-0">Created</th>
											<th className="pt-0">Updated</th>
                                            {/* <th className="pt-0 text-right">Actions</th> */}
										</tr>
									</thead>
									<tbody>
                                        {bookings.length > 0 && bookings.map((item, idx) => (
                                        <tr key={item.id}>
                                            <td>{idx + 1}</td>
                                            <td>{item.customer.title} {item.customer.first_name[0]} {item.customer.last_name} - {item.customer.id_number}</td>
                                            <td>{item.year} {item.make} {item.model} - {item.reg_number}</td>
                                            <td>{item.dealership}</td>
                                            <td>{moment(item.created).format('DD MMM, YYYY')}</td>
                                            <td>{item.updated ? moment(item.updated).format('DD MMM, YYYY') : '-'}</td>
                                            {/* <td className="text-right"></td> */}
                                        </tr>))}
                                        {bookings.length === 0 &&
                                        <tr><td colSpan={12} className="text-center">No booking record available</td></tr>}
                                    </tbody>
                                </table>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Layout>
    );
};

export default ServiceBookings;
