import React, { useState } from 'react';
import useForm from 'react-hook-form';
import { useAuth } from 'context/auth';
import { Card, Col, Row } from 'reactstrap';
import { AuthProvider, SessionProvider } from 'providers';

const Login = () => {
    const { setAuthTokens } = useAuth();
    const { register, handleSubmit, errors } = useForm();
    const [ isSubmitting, setIsSubmitting ] = useState(false);
    const [ showError, setShowError ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState(false);

    const onSubmit = data => {
        handleLogin(data);
    };

    async function handleLogin(data) {
        setIsSubmitting(true);
        const auth = await AuthProvider.login(data);
        if (auth.status === 'success') {
            await SessionProvider.start({
                payload: auth.data,
                expiration: 21600000,
            });
            setAuthTokens(auth.data);
            window.location = '/dashboard';
        } else {
            setErrorMessage(auth.message);
            setShowError(true);
            setIsSubmitting(false);
        }
    }

    return (
        <div className="main-wrapper">
            <div className="page-wrapper full-page">
                <div className="page-content d-flex align-items-center justify-content-center">
                    <Row className="w-100 mx-0 auth-page">
                        <Col md={8} xl={6} className="mx-auto">
                            <Card>
                                <Row>
                                    <Col md={4} className="pr-md-0">
                                        <div className="auth-left-wrapper" />
                                    </Col>
                                    <Col md={8} className="pl-md-0">
                                        <div className="auth-form-wrapper px-4 py-5">
                                            <a href="/" className="noble-ui-logo d-block mb-2">
                                                muji<span>cms</span>
                                            </a>
                                            <h5 className="text-muted font-weight-normal mb-4">
                                                Welcome back! Log in to your account.
                                            </h5>
                                            {showError &&
                                            <div className="alert alert-danger" role="alert">
                                                {errorMessage}
                                            </div>}
                                            <form className="forms-sample" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                                                <div className={`form-group ${errors.user ? 'has-danger' : ''}`}>
                                                    <label htmlFor="username">Username</label>
                                                    <input
                                                        type="email"
                                                        name="user"
                                                        className={`form-control ${errors.user ? 'form-control-danger' : ''}`}
                                                        id="username"
                                                        placeholder="Enter username"
                                                        ref={register({ required: true, minLength: 4 })}
                                                        disabled={isSubmitting}
                                                    />
                                                    {errors.user &&
                                                    <label id="name-error" className="error mt-2 text-danger" htmlFor="username">
                                                        Please enter a valid username
                                                    </label>}
                                                </div>
                                                <div className={`form-group ${errors.password ? 'has-danger' : ''}`}>
                                                    <label htmlFor="password">Password</label>
                                                    <input
                                                        type="password"
                                                        name="password"
                                                        className={`form-control ${errors.password ? 'form-control-danger' : ''}`}
                                                        id="password"
                                                        autoComplete="current-password"
                                                        placeholder="Password"
                                                        ref={register({ required: true, minLength: 4 })}
                                                        disabled={isSubmitting}
                                                    />
                                                    {errors.password &&
                                                    <label id="name-error" className="error mt-2 text-danger" htmlFor="password">
                                                        Please enter a valid password
                                                    </label>}
                                                </div>
                                                <div className="form-check form-check-flat form-check-primary">
                                                    <label className="form-check-label">
                                                        <input type="checkbox" className="form-check-input" />
                                                        Remember me
                                                        <i className="input-frame" />
                                                    </label>
                                                </div>
                                                <div className="mt-3">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary mr-2 mb-2 mb-md-0 text-white"
                                                        disabled={isSubmitting}
                                                    >
                                                        {isSubmitting ? 'Processing...' : 'Log me in'}
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
};

export default Login;
