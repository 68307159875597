import React from 'react';
import PropTypes from 'prop-types';

const Breadcrumb = props => {
    const { title, actionButtons } = props;
    return (
        <div className="d-flex justify-content-between align-items-center flex-wrap grid-margin">
            <div>
                <h4 className="mb-3 mb-md-0">{title}</h4>
            </div>
            {actionButtons.length > 0 &&
            <div className="d-flex align-items-center flex-wrap text-nowrap">
                {/* <div className="input-group date datepicker dashboard-date mr-2 mb-2 mb-md-0 d-md-none d-xl-flex" id="dashboardDate">
                    <span className="input-group-addon bg-transparent"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-calendar  text-primary"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg></span>
                    <input type="text" className="form-control" />
                </div> */}
                {actionButtons.map((button, idx) => (
                <React.Fragment key={idx.toString()}>
                    {button}
                </React.Fragment>))}
            </div>}
        </div>
    );
};

Breadcrumb.propTypes = {
    title: PropTypes.string.isRequired,
    actionButtons: PropTypes.instanceOf(Array),
};

Breadcrumb.defaultProps = {
    actionButtons: [],
};

export default Breadcrumb;
