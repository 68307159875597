import React, { useState } from 'react';
import {
	BrowserRouter as Router,
	Route,
	Redirect,
	Switch
} from 'react-router-dom';

import PrivateRoute from './PrivateRoute';
import { AuthContext } from 'context/auth';

// pages/routes
import Login from 'pages/Auth/Login';

// page not found
import PageNotFound from 'pages/PageNotFound';

// private routes
import Customers from 'pages/Customers';
import Dashboard from 'pages/Dashboard';
import FinanceApplications from 'pages/FinanceApplications';
import Profile from 'pages/User/Profile';
import ServiceBookings from 'pages/ServiceBookings';
import Users from 'pages/Users';
import Roles from 'pages/Users/Roles';
import Vehicles from 'pages/Vehicles';
import VehicleMakes from 'pages/Vehicles/Makes';
import VehicleModels from 'pages/Vehicles/Models';
import VehicleTags from 'pages/Vehicles/Tags';
import AddVehicle from 'pages/Vehicles/Add';
import EditVehicle from 'pages/Vehicles/Edit';

const App = () => {
	const [authTokens, setAuthTokens] = useState();

	const setTokens = (data) => {
		localStorage.setItem('tokens', JSON.stringify(data));
		setAuthTokens(data);
	}

	return (
		<AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens }}>
			<Router basename={process.env.PUBLIC_URL}>
				<Switch>
					<Redirect exact from="/" to="/dashboard" />
					<Route exact path="/auth/login" component={Login} />
					<PrivateRoute exact path="/customers" component={Customers} />
					<PrivateRoute exact path="/dashboard" component={Dashboard} />
					<PrivateRoute exact path="/finance-applications" component={FinanceApplications} />
					<PrivateRoute exact path="/profile" component={Profile} />
					<PrivateRoute exact path="/service-bookings" component={ServiceBookings} />
					<PrivateRoute exact path="/users" component={Users} />
					<PrivateRoute exact path="/users/roles" component={Roles} />
					<PrivateRoute exact path="/vehicles" component={Vehicles} />
					<PrivateRoute exact path="/vehicles/add" component={AddVehicle} />
					<PrivateRoute exact path="/vehicles/makes" component={VehicleMakes} />
					<PrivateRoute exact path="/vehicles/models" component={VehicleModels} />
					<PrivateRoute exact path="/vehicles/tags" component={VehicleTags} />
					<PrivateRoute exact path="/vehicles/edit/:id" component={EditVehicle} />
					<Route component={PageNotFound} />
				</Switch>
			</Router>
		</AuthContext.Provider>
	)
}

export default App;
