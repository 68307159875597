import React from 'react';
import moment from 'moment';

const Footer = props => {
    return (
        <footer className="footer d-flex flex-column flex-md-row align-items-center justify-content-between">
            <p className="text-muted text-center text-md-left">
                Copyright &copy;&nbsp;
                {moment().format('YYYY')} <a href="https://www.mujimotorgroup.co.za" target="_blank" rel="noopener noreferrer">
                    MUJI Motor Group
                </a>
                . All rights reserved
            </p>
            <p className="text-muted text-center text-md-left mb-0 d-none d-md-block">
                Developed by&nbsp;
                <a href="https://www.bomanisystems.com" target="_blank" rel="noopener noreferrer">
                    Bomani Systems
                </a>
            </p>
        </footer>
    );
};

export default Footer;
