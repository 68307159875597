import React, { useEffect, useState } from 'react';
import useForm from 'react-hook-form';
import { Col, Row } from 'reactstrap';
import { FeaturesProvider, ManufacturersProvider, ModelsProvider } from 'providers';

const VehicleAttributes = props => {
    const {
        activeTab,
        body_tyle,
        city_mpg,
        colour,
        current_mileage,
        drivetrain,
        engine,
        fuel_economy,
        fuel_type,
        highway_mpg,
        interior_colour,
        label,
        model,
        price,
        registration_year,
        regular_price,
        stock_number,
        tax_label,
        transmission,
        trim,
        vehicle_status,
        video_link,
        vin_number,
        variant,
        handleSaveProceed,
        setActiveTab,
        setPageLoading,
    } = props;
    const { register, handleSubmit, errors } = useForm();
    const [ models, setModels ] = useState([]);
    const [ makes, setMakes ] = useState([]);
    const [ features, setFeatures ] = useState({});

	useEffect(() => {
		const fetchData = async () => {
            if (model.manufacturer_id) {
                const models = await ModelsProvider.getAll(`?manufacturer_id=${model.manufacturer_id}&active=true`);
                setModels(models.results);
            }
            const brands = await ManufacturersProvider.getAll();
            const features = await FeaturesProvider.getAll();
            setMakes(brands.results);
            if (features) {
                if (features.results) {
                    if (features.results.length > 0) {
                        const groups = {};
                        features.results.map(item => {
                            const key = item
                                .group
                                .toLowerCase()
                                .split(' ')
                                .join('_');
                            if (groups[key]) {
                                groups[key].push({
                                    label: item.label,
                                    description: item.description,
                                });
                            } else {
                                groups[key] = [];
                                groups[key].push({
                                    label: item.label,
                                    description: item.description,
                                });
                            }
                            return true;
                        });
                        setFeatures(groups);
                    }
                }
            }
            setPageLoading(false);
		};
		fetchData();
    }, [ model, setFeatures, setMakes, setModels, setPageLoading ]);

    const onSubmit = data => {
        handleSaveProceed(activeTab, 'attributes',  data);
    };
    
    const handleMakeChange = async (val) => {
        setModels([]);
        if (val.length > 0) {
            setPageLoading(true);
            const models = await ModelsProvider.getAll(`?manufacturer_id=${val}`);
            setModels(models.results);
            setPageLoading(false);
        }
        return true;
    }

    return (
        <>
            <div className="content clearfix" style={{ minHeight: '100vh', overflow: 'hidden' }}>
                <h2 id="wizardVertical-h-0" tabIndex="-1" className="title current">Vehicle Attributes</h2>
                <section id="wizardVertical-p-0" role="tabpanel" aria-labelledby="wizardVertical-h-0" className="body current" aria-hidden="false">
                    <h4>Vehicle Attributes</h4>
                    <p className="card-description">
                        Complete vehicle attributes on the form below and proceed to the next step.
                    </p>
                    <form id="signupForm" className="cmxform" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col sm={3}>
                                <div className={`form-group ${errors.registration_year ? 'has-danger' : ''}`}>
                                    <label htmlFor="registration_year">Year</label>
                                    <select
                                        className={`form-control ${errors.registration_year ? 'form-control-danger' : ''}`}
                                        name="registration_year"
                                        id="registration_year"
                                        defaultValue={registration_year}
                                        ref={register({ required: false })}
                                    >
                                        <option value="">Select Year</option>
                                        <option value="2020">2020</option>
                                        <option value="2019">2019</option>
                                        <option value="2018">2018</option>
                                        <option value="2017">2017</option>
                                        <option value="2016">2016</option>
                                        <option value="2015">2015</option>
                                        <option value="2014">2014</option>
                                        <option value="2013">2013</option>
                                        <option value="2012">2012</option>
                                        <option value="2011">2011</option>
                                        <option value="2010">2010</option>
                                        <option value="2009">2009</option>
                                        <option value="2008">2008</option>
                                        <option value="2007">2007</option>
                                        <option value="2006">2006</option>
                                        <option value="2005">2005</option>
                                        <option value="2004">2004</option>
                                        <option value="2003">2003</option>
                                        <option value="2002">2002</option>
                                        <option value="2001">2001</option>
                                        <option value="2000">2000</option>
                                        <option value="1999">1999</option>
                                        <option value="1998">1998</option>
                                        <option value="1997">1997</option>
                                        <option value="1996">1996</option>
                                        <option value="1995">1995</option>
                                        <option value="1994">1994</option>
                                        <option value="1993">1993</option>
                                        <option value="1992">1992</option>
                                        <option value="1991">1991</option>
                                        <option value="1990">1990</option>
                                    </select>
                                </div>
                            </Col>
                            <Col sm={3}>
                                <div className={`form-group ${errors.make ? 'has-danger' : ''}`}>
                                    <label htmlFor="make" className="required">Make</label>
                                    {makes.length > 0 &&
                                    <select
                                        className={`form-control ${errors.make ? 'form-control-danger' : ''}`}
                                        name="make"
                                        id="make"
                                        defaultValue={model.manufacturer_id}
                                        ref={register({ required: true })}
                                        onChange={e => handleMakeChange(e.target.value)}
                                    >
                                        <option value="">Select Make</option>
                                        {makes.length > 0 && makes.map(item => (
                                        <option key={item.id} value={item.id}>{item.description}</option>))}
                                    </select>}
                                </div>
                            </Col>
                            <Col sm={3}>
                                <div className={`form-group ${errors.model_id ? 'has-danger' : ''}`}>
                                    <label htmlFor="model" className="required">Model</label>
                                    {models.length > 0 && 
                                    <select
                                        className={`form-control ${errors.model_id ? 'form-control-danger' : ''}`}
                                        name="model_id"
                                        id="model"
                                        defaultValue={model.id}
                                        ref={register({ required: true })}
                                        disabled={models.length === 0}
                                    >
                                        <option value="">Select Model</option>
                                        {models.map(item => (
                                        <option key={item.id} value={item.id}>{item.description}</option>))}
                                    </select>}
                                </div>
                            </Col>
                            <Col sm={3}>
                                <div className={`form-group ${errors.stock_number ? 'has-danger' : ''}`}>
                                    <label htmlFor="stock_number">Stock Number</label>
                                    <input
                                        type="text"
                                        className={`form-control ${errors.stock_number ? 'form-control-danger' : ''}`}
                                        placeholder="Enter Stock Number"
                                        name="stock_number"
                                        id="stock_number"
                                        defaultValue={stock_number}
                                        ref={register({ required: false })}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6}>
                                <div className={`form-group ${errors.variant ? 'has-danger' : ''}`}>
                                    <label htmlFor="variant" className="required">Vehicle Variant</label>
                                    <input
                                        type="text"
                                        className={`form-control ${errors.name ? 'form-control-danger' : ''}`}
                                        placeholder="Enter Vehicle Variant"
                                        name="variant"
                                        id="variant"
                                        defaultValue={variant}
                                        ref={register({ required: true })}
                                    />
                                </div>
                            </Col>
                            <Col sm={6}>
                                <div className={`form-group ${errors.vin_number ? 'has-danger' : ''}`}>
                                    <label htmlFor="vin_number">VIN Number</label>
                                    <input
                                        type="text"
                                        className={`form-control ${errors.vin_number ? 'form-control-danger' : ''}`}
                                        placeholder="Enter VIN Number"
                                        name="vin_number"
                                        id="vin_number"
                                        defaultValue={vin_number}
                                        ref={register({ required: false })}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6}>
                                <div className={`form-group ${errors.trim ? 'has-danger' : ''}`}>
                                    <label htmlFor="trim">Trim</label>
                                    <input
                                        type="text"
                                        className={`form-control ${errors.trim ? 'form-control-danger' : ''}`}
                                        placeholder="Enter Trim"
                                        name="trim"
                                        id="trim"
                                        defaultValue={trim}
                                        ref={register({ required: false })}
                                    />
                                </div>
                            </Col>
                            <Col sm={6}>
                                <div className={`form-group ${errors.label ? 'has-danger' : ''}`}>
                                    <label>&nbsp;</label>
                                    <Row>
                                        <Col md={12}>
                                            <div>
                                                <div className="form-check form-check-inline">
                                                    <label className="form-check-label" htmlFor="new">
                                                        <input
                                                            type="radio"
                                                            className="form-check-input"
                                                            name="label"
                                                            id="new"
                                                            value="New"
                                                            ref={register({ required: true })}
                                                            defaultChecked={label.toLowerCase() === 'new'}
                                                        />
                                                        New&nbsp;&nbsp;&nbsp;&nbsp;
                                                        <i className="input-frame" />
                                                    </label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <label className="form-check-label" htmlFor="mastercars">
                                                        <input
                                                            type="radio"
                                                            className="form-check-input"
                                                            name="label"
                                                            id="mastercars"
                                                            value="MasterCars"
                                                            ref={register({ required: true })}
                                                            defaultChecked={label.toLowerCase() === 'mastercars'}
                                                        />
                                                        MasterCars&nbsp;&nbsp;&nbsp;&nbsp;
                                                        <i className="input-frame" />
                                                    </label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <label className="form-check-label" htmlFor="demo">
                                                        <input
                                                            type="radio"
                                                            className="form-check-input"
                                                            name="label"
                                                            id="demo"
                                                            value="Demo"
                                                            ref={register({ required: true })}
                                                            defaultChecked={label.toLowerCase() === 'demo'}
                                                        />
                                                        Demo
                                                        <i className="input-frame" />
                                                    </label>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={3}>
                                <div className={`form-group ${errors.body_style ? 'has-danger' : ''}`}>
                                    <label htmlFor="body_style">Body Style</label>
                                    {features.body_style &&
                                    <select
                                        className={`form-control ${errors.body_style ? 'form-control-danger' : ''}`}
                                        name="body_style"
                                        id="body_style"
                                        defaultValue={body_tyle}
                                        ref={register({ required: false })}
                                        disabled={(!features.body_style)}
                                    >
                                        <option value="">Select Body Style</option>
                                        {features.body_style.map(item => (
                                        <option key={item.label} value={item.label}>{item.label}</option>))}
                                    </select>}
                                </div>
                            </Col>
                            <Col sm={3}>
                                <div className={`form-group ${errors.transmission ? 'has-danger' : ''}`}>
                                    <label htmlFor="transmission">Transmission</label>
                                    {features.transmission && 
                                    <select
                                        className={`form-control ${errors.transmission ? 'form-control-danger' : ''}`}
                                        name="transmission"
                                        id="transmission"
                                        defaultValue={transmission}
                                        ref={register({ required: false })}
                                        disabled={(!features.transmission)}
                                    >
                                        <option value="">Select Transmission</option>
                                        {features.transmission.map(item => (
                                        <option key={item.label} value={item.label}>{item.label}</option>))}
                                    </select>}
                                </div>
                            </Col>
                            <Col sm={3}>
                                <div className={`form-group ${errors.engine ? 'has-danger' : ''}`}>
                                    <label htmlFor="engine">Engine</label>
                                    <input
                                        type="text"
                                        className={`form-control ${errors.engine ? 'form-control-danger' : ''}`}
                                        placeholder="Enter Engine"
                                        name="engine"
                                        id="engine"
                                        defaultValue={engine}
                                        ref={register({ required: false })}
                                    />
                                </div>
                            </Col>
                            <Col sm={3}>
                                <div className={`form-group ${errors.drivetrain ? 'has-danger' : ''}`}>
                                    <label htmlFor="drivetrain">Drivetrain</label>
                                    <input
                                        type="text"
                                        className={`form-control ${errors.drivetrain ? 'form-control-danger' : ''}`}
                                        placeholder="Enter Drivetrain"
                                        name="drivetrain"
                                        id="drivetrain"
                                        defaultValue={drivetrain}
                                        ref={register({ required: false })}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={3}>
                                <div className={`form-group ${errors.fuel_type ? 'has-danger' : ''}`}>
                                    <label htmlFor="fuel_type">Fuel Type</label>
                                    {features.fuel_type &&
                                    <select
                                        className={`form-control ${errors.fuel_type ? 'form-control-danger' : ''}`}
                                        name="fuel_type"
                                        id="fuel_type"
                                        defaultValue={fuel_type}
                                        ref={register({ required: false })}
                                        disabled={(!features.fuel_type)}
                                    >
                                        <option value="">Select Fuel Type</option>
                                        {features.fuel_type.map(item => (
                                        <option key={item.label} value={item.label}>{item.label}</option>))}
                                    </select>}
                                </div>
                            </Col>
                            <Col sm={3}>
                                <div className={`form-group ${errors.fuel_economy ? 'has-danger' : ''}`}>
                                    <label htmlFor="fuel_economy">Fuel Economy</label>
                                    <input
                                        className={`form-control ${errors.fuel_economy ? 'form-control-danger' : ''}`}
                                        name="fuel_economy"
                                        id="fuel_economy"
                                        placeholder="Enter Fuel Economy"
                                        defaultValue={fuel_economy}
                                        ref={register({ required: false })}
                                    />
                                </div>
                            </Col>
                            <Col sm={3}>
                                <div className={`form-group ${errors.current_mileage ? 'has-danger' : ''}`}>
                                    <label htmlFor="current_mileage">Mileage</label>
                                    <input
                                        type="text"
                                        className={`form-control ${errors.current_mileage ? 'form-control-danger' : ''}`}
                                        placeholder="Enter Mileage"
                                        name="current_mileage"
                                        id="current_mileage"
                                        defaultValue={current_mileage}
                                        ref={register({ required: false })}
                                    />
                                </div>
                            </Col>
                            <Col sm={3}>
                                <div className={`form-group ${errors.colour ? 'has-danger' : ''}`}>
                                    <label htmlFor="colour">Exterior Colour</label>
                                    {features.colour && 
                                    <select
                                        className={`form-control ${errors.colour ? 'form-control-danger' : ''}`}
                                        name="colour"
                                        id="colour"
                                        defaultValue={colour}
                                        ref={register({ required: false })}
                                        disabled={(!features.colour)}
                                    >
                                        <option value="">Select Exterior Colour</option>
                                        {features.colour.map(item => (
                                        <option key={item.label} value={item.label}>{item.label}</option>))}
                                    </select>}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={3}>
                                <div className={`form-group ${errors.interior_colour ? 'has-danger' : ''}`}>
                                    <label htmlFor="interior_colour">Interior Colour</label>
                                    {features.colour &&
                                    <select
                                        className={`form-control ${errors.interior_colour ? 'form-control-danger' : ''}`}
                                        name="interior_colour"
                                        id="interior_colour"
                                        defaultValue={interior_colour}
                                        ref={register({ required: false })}
                                        disabled={(!features.colour)}
                                    >
                                        <option value="">Select Interior Colour</option>
                                        {features.colour.map(item => (
                                        <option key={item.label} value={item.label}>{item.label}</option>))}
                                    </select>}
                                </div>
                            </Col>
                            <Col sm={3}>
                                <div className={`form-group ${errors.regular_price ? 'has-danger' : ''}`}>
                                    <label htmlFor="regular_price">Regular Price</label>
                                    <input
                                        type="text"
                                        className={`form-control ${errors.regular_price ? 'form-control-danger' : ''}`}
                                        placeholder="Enter Regular Price"
                                        name="regular_price"
                                        id="regular_price"
                                        defaultValue={regular_price}
                                        ref={register({ required: false })}
                                    />
                                </div>
                            </Col>
                            <Col sm={3}>
                                <div className={`form-group ${errors.price ? 'has-danger' : ''}`}>
                                    <label htmlFor="price" className="required">Sale Price</label>
                                    <input
                                        type="text"
                                        className={`form-control ${errors.price ? 'form-control-danger' : ''}`}
                                        placeholder="Enter Sale Price"
                                        name="price"
                                        id="price"
                                        defaultValue={price}
                                        ref={register({ required: true })}
                                    />
                                </div>
                            </Col>
                            <Col sm={3}>
                                <div className={`form-group ${errors.tax_label ? 'has-danger' : ''}`}>
                                    <label htmlFor="tax_label">Tax Label</label>
                                    <input
                                        type="text"
                                        className={`form-control ${errors.tax_label ? 'form-control-danger' : ''}`}
                                        placeholder="Enter Tax Label"
                                        name="tax_label"
                                        id="tax_label"
                                        defaultValue={tax_label}
                                        ref={register({ required: false })}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={3}>
                                <div className={`form-group ${errors.city_mpg ? 'has-danger' : ''}`}>
                                    <label htmlFor="city_mpg">City MPG</label>
                                    <input
                                        type="text"
                                        className={`form-control ${errors.city_mpg ? 'form-control-danger' : ''}`}
                                        placeholder="Enter City MPG"
                                        name="city_mpg"
                                        id="city_mpg"
                                        defaultValue={city_mpg}
                                        ref={register({ required: false })}
                                    />
                                </div>
                            </Col>
                            <Col sm={3}>
                                <div className={`form-group ${errors.highway_mpg ? 'has-danger' : ''}`}>
                                    <label htmlFor="highway_mpg">Highway MPG</label>
                                    <input
                                        type="text"
                                        className={`form-control ${errors.highway_mpg ? 'form-control-danger' : ''}`}
                                        placeholder="Enter Highway MPG"
                                        name="highway_mpg"
                                        id="highway_mpg"
                                        defaultValue={highway_mpg}
                                        ref={register({ required: false })}
                                    />
                                </div>
                            </Col>
                            <Col sm={6}>
                                <div className={`form-group ${errors.video_link ? 'has-danger' : ''}`}>
                                    <label htmlFor="video_link">Video Link</label>
                                    <input
                                        type="text"
                                        className={`form-control ${errors.video_link ? 'form-control-danger' : ''}`}
                                        placeholder="Enter Video Link"
                                        name="video_link"
                                        id="video_link"
                                        defaultValue={video_link}
                                        ref={register({ required: false })}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <div className={`form-group ${errors.vehicle_status ? 'has-danger' : ''}`}>
                                    <label className="required">Vehicle Status</label>
                                    <Row>
                                        <Col md={12}>
                                            <div>
                                                <div className="form-check form-check-inline">
                                                    <label className="form-check-label" htmlFor="unsold">
                                                        <input
                                                            type="radio"
                                                            className="form-check-input"
                                                            name="vehicle_status"
                                                            id="unsold"
                                                            value="UnSold"
                                                            defaultChecked={vehicle_status.toLowerCase() === 'unsold'}
                                                        />
                                                        UnSold&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                        <i className="input-frame" />
                                                    </label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <label className="form-check-label" htmlFor="sold">
                                                        <input
                                                            type="radio"
                                                            className="form-check-input"
                                                            name="vehicle_status"
                                                            id="sold"
                                                            value="Sold"
                                                            defaultChecked={vehicle_status.toLowerCase() === 'sold'}
                                                        />
                                                        Sold
                                                        <i className="input-frame" />
                                                    </label>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </form>
                </section>
            </div>
            <div className="actions clearfix">
                <ul role="menu" aria-label="Pagination">
                    <li className={activeTab === 0 ? 'disabled' : ''} aria-disabled={activeTab === 0}>
                        <a
                            href="#previous"
                            role="menuitem"
                            onClick={e => {
                                e.preventDefault();
                                if (activeTab > 0) {
                                    return setActiveTab(activeTab - 1);
                                }
                            }}
                        >Previous</a>
                    </li>
                    {activeTab < 4 &&
                    <li aria-hidden="false" aria-disabled="false">
                        <button
                            type="submit"
                            form="signupForm"
                            className="btn btn-primary"
                            onClick={handleSubmit(onSubmit)}
                        >
                            Next
                        </button>
                    </li>}
                    {activeTab === 4 &&
                    <li aria-hidden="true">
                        <a href="#finish" role="menuitem">Finish</a>
                    </li>}
                </ul>
            </div>
        </>
    );
};

export default VehicleAttributes;