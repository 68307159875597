import React from 'react';
import { Col, Row } from 'reactstrap';

const PageNotFound = () => {
    return (
        <div className="main-wrapper">
            <div className="page-wrapper full-page">
                <div className="page-content d-flex align-items-center justify-content-center">
                    <Row className="w-100 mx-0 auth-page">
                        <Col md={8} xl={6} className="mx-auto d-flex flex-column align-items-center">
                            <img src={require("images/404.svg")} class="img-fluid mb-2" alt="404" />
                            <h1 className="font-weight-bold mb-22 mt-2 tx-80 text-muted">404</h1>
                            <h4 className="mb-2">Page Not Found</h4>
                            <h6 className="text-muted mb-3 text-center">Oopps!! The page you were looking for doesn't exist.</h6>
                            <a href="/" className="btn btn-primary">Back to dashboard/login</a>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
};

export default PageNotFound;

