import React, { useCallback } from 'react';
import useForm from 'react-hook-form';
import { useDropzone } from 'react-dropzone';

const VehicleImages = props => {
    const {
        activeTab,
        handleSaveProceed,
        setActiveTab,
    } = props;
    const { handleSubmit } = useForm();

    const onDrop = useCallback(acceptedFiles => {
        console.log(acceptedFiles);
    }, []);
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    const onSubmit = data => {
        handleSaveProceed(activeTab, 'images',  data);
    };

    return (
        <>
            <div className="content clearfix">
                <h2 id="wizardVertical-h-1" tabIndex="-1" className="title current">Vehicle Images</h2>
                <section id="wizardVertical-p-1" role="tabpanel" aria-labelledby="wizardVertical-h-1" className="body current" aria-hidden="false">
                    <h4>Vehicle Images</h4>
                    <p className="card-description">
                        Drag 'n' drop some files here, or click to select files below.
                    </p>
                    <form className="dropzone dz-clickable" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                        <div {...getRootProps()} className="dz-default dz-message">
                            <input {...getInputProps()} />
                            {
                                isDragActive ?
                                <button className="dz-button" type="button">Drop the files here ...</button> :
                                <button className="dz-button" type="button">Drag 'n' drop some files here, or click to select files</button>
                            }
                        </div>
                    </form>
                </section>
            </div>
            <div className="actions clearfix">
                <ul role="menu" aria-label="Pagination">
                    <li className={activeTab === 0 ? 'disabled' : ''} aria-disabled={activeTab === 0}>
                        <a
                            href="#previous"
                            role="menuitem"
                            onClick={e => {
                                e.preventDefault();
                                if (activeTab > 0) {
                                    return setActiveTab(activeTab - 1);
                                }
                            }}
                        >Previous</a>
                    </li>
                    {activeTab < 4 &&
                    <li aria-hidden="false" aria-disabled="false">
                        <button
                            type="submit"
                            form="signupForm"
                            className="btn btn-primary"
                            onClick={handleSubmit(onSubmit)}
                        >
                            Next
                        </button>
                    </li>}
                    {activeTab === 4 &&
                    <li aria-hidden="true">
                        <a href="#finish" role="menuitem">Finish</a>
                    </li>}
                </ul>
            </div>
        </>
    );
};

export default VehicleImages;
