var stage = 'prod';

export default {
    stage,
    results: {
        offset: 0,
        limit: 100,
    },
    filestorage: {
        vehicles: 'https://muji-file-storage.s3.eu-west-2.amazonaws.com/vehicles/',
    },
    api: {
        dev: {
            baseurl: 'https://ymmc3e0mz5.execute-api.eu-west-2.amazonaws.com/dev/1/',
        },
        test: {
            baseurl: 'https://adw65cvj1d.execute-api.eu-west-2.amazonaws.com/test/1/',
        },
        prod: {
            baseurl: 'https://ibhehqa1j5.execute-api.eu-west-2.amazonaws.com/prod/1/',
        },
    },
};
