// import EditRole from './EditRole';
// import EditUser from './EditUser';
import ImportVehicles from './ImportVehicles';
// import NewRole from './NewRole';
// import NewTicket from './NewTicket';
// import NewUser from './NewUser';
import Results from './Results';

export default {
    // EditRole,
    // EditUser,
    ImportVehicles,
    // NewRole,
    // NewTicket,
    // NewUser,
    Results,
};