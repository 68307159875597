import axios from 'axios';
import config from '../config';
import SessionProvider from './SessionProvider';

const baseurl = config.api[config.stage].baseurl;
const session = SessionProvider.get();
const token = SessionProvider.getToken();
let headers = {};

if (session.isValid) {
  headers = {
    'Authorization': `Bearer ${token}`,
  }
}

class CustomersProvider {
    static async getAll(query = '') {
        return await axios({
            url: `${baseurl}customers${query}`,
            method: 'GET',
            mode: 'no-cors',
            crossdomain: true,
            headers,
        })
        .then((json) => json.data)
        .then((res) => res)
        .catch((err) => {
          if (err.response) return err.response.data;
          return err;
        });
    }

    static async get(id) {
        return await axios({
            url: `${baseurl}customers/${id}`,
            method: 'GET',
            mode: 'no-cors',
            crossdomain: true,
            headers,
        })
        .then((json) => json.data)
        .then((res) => res)
        .catch((err) => {
          if (err.response) return err.response.data;
          return err;
        });
    }
}

export default CustomersProvider;
