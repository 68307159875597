import AdditionalInformation from './AdditionalInformation';
import VehicleAttributes from './VehicleAttributes';
import VehicleImages from './VehicleImages';
import VehicleLocation from './VehicleLocation';
import VehiclePdfBrochure from './VehiclePdfBrochure';

export default {
    AdditionalInformation,
    VehicleAttributes,
    VehicleImages,
    VehicleLocation,
    VehiclePdfBrochure,
};
