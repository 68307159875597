import React from 'react';
import { Modal } from 'react-bootstrap';

const Results = props => {
    const {
        show,
        setShow,
        onClose,
        data,
    } = props;

    const handleClose = () => {
        setShow(false);
        if (onClose) {
            onClose();
        }
        return true;
    };

    return (
        <Modal show={show} onHide={handleClose} centered size="sm">
            <Modal.Header closeButton className="faded smaller">
                <h5 className={`modal-title ${data.titleClass}`}>{data.title}</h5>
            </Modal.Header>
            <Modal.Body>
                <p className={data.bodyClass}>{data.body}</p>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-link" data-dismiss="modal" type="button" onClick={e => { e.preventDefault(); handleClose(); }}> Close</button>
            </Modal.Footer>
        </Modal>
    );
};

export default Results;
