import React, { useEffect, useState } from 'react';
import moment from 'moment';
import CurrencyFormat from 'react-currency-format';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { Layout } from 'containers';
import { Breadcrumb, FeatherIcon, Loader, Modals } from 'components';
import { VehiclesProvider } from 'providers';

const Vehicles = () => {
    const [ pageLoading, setPageLoading ] = useState(true);
    const [ showImportModal, setShowImportModal ] = useState(false);
    const [ showResultsModal, setShowResultsModal ] = useState(false);
    const [ resultsData, setResultsData ] = useState({});
    const [ filteredVehicles, setFilteredVehicles ] = useState([]);
    const [ vehicles, setVehicles ] = useState([]);
    const [ vehicleCount, setVehicleCount ] = useState(null);
    const [ newVehiclesCount, setNewVehiclesCount ] = useState(null);
    const [ page, setPage ] = useState(1);
    const [ offset, setOffset ] = useState(0);
    const [ limit, setLimit ] = useState(20);
    const [ pageLength, setPageLength ] = useState(page * limit);

    const actionButtons = [
        <button
            type="button"
            className="btn btn-outline-primary btn-icon-text mr-2 d-none d-md-block"
            onClick={() => setShowImportModal(true)}
        >
            <FeatherIcon icon="download" classes="btn-icon-prepend" />
            Import Vehicles
        </button>,
        <a href="/vehicles/add" className="btn btn-primary btn-icon-text mb-2 mb-md-0">
            <FeatherIcon icon="plus" classes="btn-icon-prepend" />
            Add Vehicle
        </a>
    ];

	useEffect(() => {
		const fetchData = async () => {
            const vehicles = await VehiclesProvider.getAll();
            if (vehicles) {
                if (vehicles.results) {
                    setFilteredVehicles(vehicles.results);
                    setVehicles(vehicles.results);
                    setVehicleCount(vehicles.results.length);

                    let newVehiclesCount = 0;
                    vehicles.results.map(item => {
                        const a = moment(item.created);
                        const b = moment();
                        if (b.diff(a, 'days') <= 2) {
                            newVehiclesCount++;
                        }
                    });
                    if (newVehiclesCount > 0) {
                        setNewVehiclesCount(newVehiclesCount);
                    }
                }
            }
            setPageLoading(false);
		};
		fetchData();
    }, [ setFilteredVehicles, setNewVehiclesCount, setPageLoading, setVehicles, setVehicleCount ]);

    const handleFilter = val => {
        const filtered = vehicles.filter(item => (
            item.name.toLowerCase().indexOf(val.toLowerCase()) !== -1
        ));
        setFilteredVehicles(filtered);
    };
    
    const handlePrev = () => {
        setOffset(offset - limit);
        setPage(page - 1);
    };
    
    const handleNext = () => {
        if (pageLength < vehicleCount) {
            const len = pageLength + (page * limit);
            const offst = offset + limit;
            setOffset(offst);
            setPage(page + 1);
            setPageLength(len < vehicleCount ? len : vehicleCount);
            if (len >= vehicleCount) {
                setLimit(vehicleCount - offst);
            } else {
                setLimit(20);
            }
            console.log(offst);
            console.log(vehicleCount);
            console.log(vehicleCount - offst);
        }
    };

    return (
        <Layout>
			{pageLoading && <Loader />}
			<Breadcrumb
                title="Manage Vehicles"
                actionButtons={actionButtons}
			/>
            <Modals.ImportVehicles
                show={showImportModal}
                setShow={setShowImportModal}
                setPageLoading={setPageLoading}
                setVehicles={setVehicles}
                setVehicleCount={setVehicleCount}
                setFilteredVehicles={setFilteredVehicles}
                setShowResults={setShowResultsModal}
                setResultsData={setResultsData}
            />
            <Modals.Results
                show={showResultsModal}
                setShow={setShowResultsModal}
                data={resultsData}
            />
            <Row className="inbox-wrapper">
                <Col lg={12}>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col lg={12} className="email-content">
                                    <div className="email-inbox-header">
                                        <Row className="row align-items-center">
                                            <Col lg={6}>
                                                <div className="email-title mb-2 mb-md-0">
                                                    <span className="icon">
                                                        <FeatherIcon icon="database" />
                                                    </span> Vehicles&nbsp;
                                                    {newVehiclesCount &&
                                                    <span className="new-messages">({newVehiclesCount} recently added vehicle(s))</span>}
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <div className="email-search">
                                                    <div className="input-group input-search">
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            placeholder="Search vehicles..."
                                                            onKeyUp={e => handleFilter(e.target.value)}
                                                        />
                                                        <span className="input-group-btn">
                                                            <button className="btn btn-outline-secondary" type="button">
                                                                <FeatherIcon icon="search" />
                                                            </button>
                                                        </span>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="email-filters d-flex align-items-center justify-content-between flex-wrap">
                                        <div />
                                        {/* <div className="email-filters-left flex-wrap d-none d-md-flex">
                                            <div className="form-check form-check-flat form-check-primary">
                                            <label className="form-check-label">
                                                <input type="checkbox" className="form-check-input" />
                                            <i className="input-frame"></i></label>
                                            </div>
                                            <div className="btn-group ml-3">
                                                <button className="btn btn-outline-primary dropdown-toggle" data-toggle="dropdown" type="button" aria-expanded="false"> With selected <span className="caret"></span></button>
                                                <div className="dropdown-menu" role="menu">
                                                    <a className="dropdown-item" href="/">Mark as read</a>
                                                    <a className="dropdown-item" href="/">Mark as unread</a><a className="dropdown-item" href="/">Spam</a>
                                                    <div className="dropdown-divider"></div><a className="dropdown-item text-danger" href="/">Delete</a>
                                                </div>
                                            </div>
                                            <div className="btn-group mb-1 mb-md-0">
                                                <button className="btn btn-outline-primary" type="button">Active</button>
                                                <button className="btn btn-outline-primary" type="button">Archived</button>
                                                <button className="btn btn-outline-primary" type="button">Inactive</button>
                                            </div>
                                            <div className="btn-group mb-1 mb-md-0 d-none d-xl-block">
                                                <button className="btn btn-outline-primary dropdown-toggle" data-toggle="dropdown" type="button">Order by <span className="caret"></span></button>
                                                <div className="dropdown-menu dropdown-menu-right" role="menu">
                                                    <a className="dropdown-item" href="/">Date</a>
                                                    <div className="dropdown-divider"></div>
                                                    <a className="dropdown-item" href="/">Brand</a>
                                                    <a className="dropdown-item" href="/">Model</a>
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="email-filters-right">
                                            <span className="email-pagination-indicator">{offset + 1}-{pageLength} of {vehicleCount}</span>
                                            <div className="btn-group email-pagination-nav">
                                                <button
                                                    className="btn btn-outline-secondary btn-icon"
                                                    type="button"
                                                    onClick={handlePrev}
                                                >
                                                    <FeatherIcon icon="chevron-left" />
                                                </button>
                                                <button
                                                    className="btn btn-outline-secondary btn-icon"
                                                    type="button"
                                                    onClick={handleNext}
                                                >
                                                    <FeatherIcon icon="chevron-right" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="email-list">
                                        {filteredVehicles.length > 0 && filteredVehicles.slice(offset, limit).map(item => (
                                        <div key={item.id} className="email-list-item">{/* .email-list-item--unread */}
                                            <div className="email-list-actions">
                                                <div className="form-check form-check-flat form-check-primary">
                                                    <label className="form-check-label">
                                                        <input type="checkbox" className="form-check-input" />
                                                        <i className="input-frame" />
                                                    </label>
                                                </div>
                                                <a className="favorite" href="/">
                                                    <span><FeatherIcon icon="star" /></span>
                                                </a>
                                            </div>
                                            <a href={`/vehicles/edit/${item.id}`} className="email-list-detail">
                                                <div>
                                                    <span className="from">
                                                        {item.name}
														<CurrencyFormat
															value={item.price}
															displayType={'text'}
															thousandSeparator={true}
															renderText={value => (
																<span> @ R {value}</span>
															)}
														/>
                                                    </span>
                                                    <p className="msg">
                                                        {item.model.manufacturer.description} - {item.model.name}
                                                    </p>
                                                </div>
                                                <span className="date">
                                                    <span className="icon">
                                                        <FeatherIcon icon="calendar" />
                                                    </span>
                                                    {moment(item.created).format('DD MMM')}
                                                </span>
                                            </a>
                                        </div>
                                        ))}
                                        {filteredVehicles.length === 0 && <div className="text-center text-muted pt-4">No match for specified filter</div>}
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Layout>
    );
};

export default Vehicles;
