import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useForm from 'react-hook-form';
import CSVReader from 'react-csv-reader';
import { Modal } from 'react-bootstrap';
import { ModelsProvider, VehiclesProvider } from 'providers';

const ImportVehicles = props => {
    const {
        show,
        setShow,
        setPageLoading,
        setVehicles,
        setVehicleCount,
        setFilteredVehicles,
        setShowResults,
        setResultsData,
        isDashboard,
    } = props;
    const [ csvData, setCsvData ] = useState(null);
    const [ csvFile, setCsvFile ] = useState(null);
    const [ componentLoading, setComponentLoading ] = useState(true);
    const [ models, setModels ] = useState([]);
    const { handleSubmit } = useForm();

	useEffect(() => {
		const fetchData = async () => {
            const models = await ModelsProvider.getAll(`?active=true&offset=0&limit=1000`);
            setModels(models.results);
            setComponentLoading(false);
        };
        fetchData();
    }, [ setComponentLoading, setModels ]);

    const handleClose = () => {
        setCsvData(null);
        setCsvFile(null);
        setShow(false);
        return true;
    }
    
    const onSubmit = () => {
        handleImport();
        setShow(false);
    };

    const getVehicleName = (year, make, model, variant) => {
        const name = `${year} ${make} ${model} ${variant}`;
        return name.trim();
    };

    const generatePermakey = (year, make, model, variant) => {
        let permakey = getVehicleName(year, make, model, variant);
        permakey = permakey.split(' ').join('-').trim();
        permakey = (permakey[0] === '-') ? permakey.substr(1) : permakey;
        return permakey.toLowerCase();
    };

    const handleImport = async () => {
        setPageLoading(true);
        const postData = [];
        const keys = [];
        csvData.map((arr, index) => {
            if (index === 0) {
                return arr.map(item => {
                    const key = item.split(' ').join('_').toLowerCase();
                    return keys.push(key);
                });
            } else {
                let obj = {};
                let make = '';
                let model = '';
                let variant = '';
                let year = '';
                arr.map((item, idx) => {
                    const key = keys[idx];
                    if (key !== 'listing_id') {
                        if (key === 'exterior_colour') {
                            return obj.colour = item;
                        }
                        if (key === 'mileage') {
                            const mileage = item.replace(/\D/g, '');
                            return obj.current_mileage = mileage;
                        }
                        if (key === 'type') {
                            return obj.label = item;
                        }
                        if (key === 'make') {
                            make = item;
                            return true;
                        }
                        if (key === 'sale_price') {
                            const price = item.replace(/\D/g, '');
                            return obj.price = price;
                        }
                        if (key === 'model') {
                            model = item;
                            return models.map(model => {
                                if (model.manufacturer.description.toLowerCase() === make.toLowerCase()
                                    && model.name.toLowerCase() === item.toLowerCase()) {
                                    return obj.model_id = model.id;
                                }
                            });
                        }
                        if (key === 'variant') variant = item;
                        if (key === 'year') {
                            year = item;
                            return obj.registration_year = item;
                        }
                        return obj[key] = item;
                    };
                    return true;
                });
                obj.permakey = generatePermakey(year, make, model, variant);
                obj.name = getVehicleName(year, make, model, variant);
                make = '';
                model = '';
                variant = '';
                year = '';
                if (obj.model_id) return postData.push(obj);
            }
        });
        const result = await VehiclesProvider.import(postData);
        if (result.status === 'success') {
            if (!isDashboard) {
                const vehicles = await VehiclesProvider.getAll();
                if (vehicles) {
                    if (vehicles.results) {
                        setFilteredVehicles(vehicles.results);
                        setVehicles(vehicles.results);
                        setVehicleCount(vehicles.results.length);
                    }
                }
            }
            setPageLoading(false);
            setResultsData({
                title: 'Vehicle Import Success!',
                titleClass: 'text-success',
                body: `${result.data.length} vehicle record(s) successfully imported!`,
                bodyClass: 'text-success',
            });
            setShowResults(true);
        } else {
            setPageLoading(false);
            setResultsData({
                title: 'Vehicle Import Error!',
                titleClass: 'text-danger',
                body: result.message,
                bodyClass: 'text-danger',
            });
            setShowResults(true);
        }
    };

    const handleForce = (data, file) => {
        setCsvData(data);
        setCsvFile(file);
    };

    return (
        <Modal show={show} onHide={handleClose} centered size="lg">
            <Modal.Header closeButton className="faded smaller">
                <h5 className="modal-title">Import New Vehicles</h5>
            </Modal.Header>
            <Modal.Body>
                <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group">
                        <label htmlFor="vehicles">File upload <span className="text-muted">[ supported format: CSV ]</span></label>
                        <div className="input-group col-xs-12">
                            <CSVReader
                                cssInputClassName="form-control file-upload-info"
                                onFileLoaded={handleForce}
                                inputId="vehicles"
                                disabled={componentLoading}
                            />
                        </div>
                        <p className="alert alert-secondary mt-3">
                            Note: This process might take a few moments, after
                            saving changes please do not close the page until
                            the process has completed!
                        </p>
                    </div>
                    <div className="form-buttons-w text-right">
                        <button className="btn btn-link" data-dismiss="modal" type="button" onClick={e => { e.preventDefault(); handleClose(); }}> Cancel</button>
                        <button
                            className="btn btn-primary"
                            type="submit"
                            disabled={!csvFile}
                        >
                            {csvFile ? 'Upload & Import' : 'No File Selected'}
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
};

ImportVehicles.propTypes = {
    isDashboard: PropTypes.bool,
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
    setPageLoading: PropTypes.func,
    setVehicles: PropTypes.func,
    setVehicleCount: PropTypes.func,
    setFilteredVehicles: PropTypes.func,
    setShowResults: PropTypes.func,
    setResultsData: PropTypes.func,
};

ImportVehicles.defaultProps = {
    isDashboard: false,
    setPageLoading: () => {},
    setVehicles: () => {},
    setVehicleCount: () => {},
    setFilteredVehicles: () => {},
    setShowResults: () => {},
    setResultsData: () => {},
};

export default ImportVehicles;
