import React, { useEffect, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { Layout } from 'containers';
import { Breadcrumb, FeatherIcon, Loader, Modals } from 'components';
import { ManufacturersProvider, StatsProvider, VehiclesProvider } from 'providers';

const Dashboard = () => {
	const [ pageLoading, setPageLoading ] = useState(true);
	const [ recentBrands, setRecentBrands ] = useState([]);
	const [ recentVehicles, setRecentVehicles ] = useState([]);
    const [ showImportModal, setShowImportModal ] = useState(false);
    const [ showResultsModal, setShowResultsModal ] = useState(false);
    const [ resultsData, setResultsData ] = useState({});
	const [ stats, setStats ] = useState({});

	const actionButtons = [
        <button
            type="button"
            className="btn btn-outline-primary btn-icon-text mr-2 d-none d-md-block"
            onClick={() => setShowImportModal(true)}
        >
            <FeatherIcon icon="download" classes="btn-icon-prepend" />
            Import Vehicles
        </button>,
	// 	<button type="button" className="btn btn-outline-primary btn-icon-text mr-2 mb-2 mb-md-0">
	// 		<FeatherIcon icon="printer" classes="btn-icon-prepend" />
	// 		Print
	// 	</button>,
	// 	<button type="button" className="btn btn-primary btn-icon-text mb-2 mb-md-0">
	// 		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-download-cloud btn-icon-prepend"><polyline points="8 17 12 21 16 17"></polyline><line x1="12" y1="12" x2="12" y2="21"></line><path d="M20.88 18.09A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.29"></path></svg>
	// 		Download Report
	// 	</button>
		<a href="/vehicles/add" className="btn btn-primary btn-icon-text mb-2 mb-md-0">
			<FeatherIcon icon="plus" classes="btn-icon-prepend" />
			Add Vehicle
		</a>
	];

	useEffect(() => {
		const fetchData = async () => {
			const recentBrands = await ManufacturersProvider.getAll(0, 10);
			const recentVehicles = await VehiclesProvider.getAll(0, 10);
			const stats = await StatsProvider.get('dashboard');
			if (stats) {
				if (stats.data) {
					setStats(stats.data);
				}
			}
			setRecentBrands(recentBrands.results);
			setRecentVehicles(recentVehicles.results);
            setPageLoading(false);
		};
		fetchData();
	}, [ setPageLoading, setRecentBrands, setRecentVehicles, setStats ]);

	return (
		<Layout>
			{pageLoading && <Loader />}
			<Breadcrumb
				title="Welcome to MUJI Dashboard"
				actionButtons={actionButtons}
			/>
            <Modals.ImportVehicles
                show={showImportModal}
                setShow={setShowImportModal}
                setPageLoading={setPageLoading}
                setShowResults={setShowResultsModal}
				setResultsData={setResultsData}
				isDashboard
            />
            <Modals.Results
                show={showResultsModal}
                setShow={setShowResultsModal}
				data={resultsData}
				onClose={() => window.location = '/dashboard'}
            />
			<Row>
				<Col xs={12} xl={12} className="stretch-card">
					<Row className="flex-grow">
						<Col md={4} className="grid-margin stretch-card">
							<Card>
								<CardBody>
									<div className="d-flex justify-content-between align-items-baseline">
										<h6 className="card-title mb-0">Vehicles</h6>
										<div className="dropdown mb-2">
											<button className="btn p-0" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
												<FeatherIcon icon="more-horizontal" classes="icon-lg text-muted pb-3px" />
											</button>
											<div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
												<a className="dropdown-item d-flex align-items-center" href="/">
													<FeatherIcon icon="edit-2" classes="icon-sm mr-2" /> <span className="">Add Vehicle</span>
												</a>
												<a className="dropdown-item d-flex align-items-center" href="/">
													<FeatherIcon icon="eye" classes="icon-sm mr-2" /> <span className="">View</span>
												</a>
											</div>
										</div>
									</div>
									<Row>
										<Col xs={6} md={12} xl={5}>
											<h3 className="mb-2">{stats.vehicles || '0'}</h3>
											{/* <div className="d-flex align-items-baseline">
												<p className="text-success">
													<span>+3.3%</span>
													<FeatherIcon icon="arrow-up" classes="icon-sm mb-1" />
												</p>
											</div> */}
										</Col>
										<Col xs={6} md={12} xl={7}>
											{/* apex chart here */}
										</Col>
									</Row>
								</CardBody>
							</Card>
						</Col>
						<Col md={4} className="grid-margin stretch-card">
							<Card>
								<CardBody>
									<div className="d-flex justify-content-between align-items-baseline">
										<h6 className="card-title mb-0">Service Bookings</h6>
										<div className="dropdown mb-2">
											<button className="btn p-0" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
												<FeatherIcon icon="more-horizontal" classes="icon-lg text-muted pb-3px" />
											</button>
											<div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
												<a className="dropdown-item d-flex align-items-center" href="/">
													<FeatherIcon icon="eye" classes="icon-sm mr-2" /> <span className="">View</span>
												</a>
											</div>
										</div>
									</div>
									<Row>
										<Col xs={6} md={12} xl={5}>
											<h3 className="mb-2">{stats.service_bookings || '0'}</h3>
											{/* <div className="d-flex align-items-baseline">
												<p className="text-success">
													<span>+1.3%</span>
													<FeatherIcon icon="arrow-up" classes="icon-sm mb-1" />
												</p>
											</div> */}
										</Col>
										<Col xs={6} md={12} xl={7}>
											{/* apex chart here */}
										</Col>
									</Row>
								</CardBody>
							</Card>
						</Col>
						<Col md={4} className="grid-margin stretch-card">
							<Card>
								<CardBody>
									<div className="d-flex justify-content-between align-items-baseline">
										<h6 className="card-title mb-0">Finance Applications</h6>
										<div className="dropdown mb-2">
											<button className="btn p-0" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
												<FeatherIcon icon="more-horizontal" classes="icon-lg text-muted pb-3px" />
											</button>
											<div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
												<a className="dropdown-item d-flex align-items-center" href="/">
													<FeatherIcon icon="eye" classes="icon-sm mr-2" /> <span className="">View</span>
												</a>
											</div>
										</div>
									</div>
									<Row>
										<Col xs={6} md={12} xl={5}>
											<h3 className="mb-2">{stats.finance_applications || '0'}</h3>
											{/* <div className="d-flex align-items-baseline">
												<p className="text-danger">
													<span>-2.8%</span>
													<FeatherIcon icon="arrow-down" classes="icon-sm mb-1" />
												</p>
											</div> */}
										</Col>
										<Col xs={6} md={12} xl={7}>
											{/* apex chart here */}
										</Col>
									</Row>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Col>
			</Row>
			<Row>
				<Col lg={5} xl={4} className="grid-margin grid-margin-xl-0 stretch-card">
					<Card>
						<CardBody>
							<div className="d-flex justify-content-between align-items-baseline mb-2">
								<h6 className="card-title mb-0">Brands</h6>
								<div className="dropdown mb-2">
									<button className="btn p-0" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
										<FeatherIcon icon="more-horizontal" classes="icon-lg text-muted pb-3px" />
									</button>
									<div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
										<a className="dropdown-item d-flex align-items-center" href="/">
											<FeatherIcon icon="eye" classes="icon-sm mr-2" /> <span className="">View</span>
										</a>
									</div>
								</div>
							</div>
							<div className="d-flex flex-column">
								{
									recentBrands.length > 0 && recentBrands.slice(0, 6).map((brand, index) => (
										<a	
											key={brand.id}
											href="/"
											className={`d-flex align-items-center border-bottom ${index === 0 ? 'pb-3' : 'py-3'}`}
										>
											<div className="mr-3">
												<img src={require("images/faces/face2.jpg")} className="rounded-circle wd-35" alt={brand.description} />
											</div>
											<div className="w-100">
												<div className="d-flex justify-content-between">
													<h6 className="text-body mb-2">{brand.description}</h6>
													<p className="text-muted tx-12">24</p>
												</div>
												<p className="text-muted tx-13">Active</p>
											</div>
										</a>
									))
								}
							</div>
						</CardBody>
					</Card>
				</Col>
				<Col lg={7} xl={8} className="grid-margin grid-margin-xl-0 stretch-card">
					<Card>
						<CardBody>
							<div className="d-flex justify-content-between align-items-baseline mb-2">
								<h6 className="card-title mb-0">Vehicles</h6>
								<div className="dropdown mb-2">
									<button className="btn p-0" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
										<FeatherIcon icon="more-horizontal" classes="icon-lg text-muted pb-3px" />
									</button>
									<div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
										<a className="dropdown-item d-flex align-items-center" href="/">
											<FeatherIcon icon="eye" classes="icon-sm mr-2" /> <span className="">View</span>
										</a>
									</div>
								</div>
							</div>
							<div className="table-responsive">
								<table className="table table-hover mb-0">
									<thead>
										<tr>
											<th className="pt-0">#</th>
											<th className="pt-0">Description</th>
											<th className="pt-0 text-right">Price</th>
										</tr>
									</thead>
									<tbody>
										{
											recentVehicles.length > 0 && recentVehicles.slice(0, 10).map((item, idx) => (
												<tr key={item.id}>
													<td>{idx + 1}</td>
													<td>{item.name}</td>
													<td className="text-right">
														<CurrencyFormat
															value={item.price}
															displayType={'text'}
															thousandSeparator={true}
															renderText={value => (
																<span>R {value}</span>
															)}
														/>
													</td>
												</tr>
											))
										}
									</tbody>
								</table>
							</div>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Layout>
	);
};

export default Dashboard;
