import React from 'react';
import { Layout } from 'containers';

const Tags = () => {
    return (
        <Layout>Tags</Layout>
    );
};

export default Tags;
