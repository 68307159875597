class SessionProvider {
    static start(data) {
        data.isValid = true;
        data.expiration = new Date().getTime() + data.expiration;
        sessionStorage.setItem('token', data.payload.token);
        sessionStorage.setItem('userSession', JSON.stringify(data));
    }

    static destroy() {
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('userSession');
    }

    static get() {
        let userSession = sessionStorage.getItem('userSession');
        if (userSession) {
            const data = JSON.parse(userSession);
            const timestamp = new Date().getTime();
            if (timestamp <= data.expiration)
                return data;
            else
                this.destroy();
        }
        return {};
    }

    static isValid() {
        let userSession = sessionStorage.getItem('userSession');
        return (userSession);
    }

    // return the token from the session storage
    static getToken() {
        return sessionStorage.getItem('token') || null;
    }
}

export default SessionProvider;
