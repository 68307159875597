import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';
import { FeatherIcon } from 'components';
import { SessionProvider } from 'providers';

const session = SessionProvider.get();
let user = {};
if (session.isValid) {
	const { payload } = session;
	user = payload.user;
}

const TopNav = () => {
	const [ showProfileDropdown, setShowProfileDropdown ] = useState(false);
	const toggleProfileDropdown = () => setShowProfileDropdown(!showProfileDropdown);
	return (
		<nav className="navbar top-navbar">
			<Container>
				<div className="navbar-content">
					<a href="/" className="navbar-brand">
						muji<span>cms</span>
					</a>
					{/* <form className="search-form">
						<div className="input-group">
							<div className="input-group-prepend">
								<div className="input-group-text">
									<FeatherIcon icon="search" />
								</div>
							</div>
							<input type="text" className="form-control" id="navbarForm" placeholder="Search here..." />
						</div>
					</form> */}
					<ul className="navbar-nav">
						{/* <li className="nav-item dropdown">
							<a className="nav-link dropdown-toggle" href="/" id="languageDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								<i className="flag-icon flag-icon-us mt-1" title="us"></i> <span className="font-weight-medium ml-1 mr-1 d-none d-md-inline-block">English</span>
							</a>
							<div className="dropdown-menu" aria-labelledby="languageDropdown">
								<a href="/" className="dropdown-item py-2"><i className="flag-icon flag-icon-us" title="us" id="us"></i> <span className="ml-1"> English </span></a>
								<a href="/" className="dropdown-item py-2"><i className="flag-icon flag-icon-fr" title="fr" id="fr"></i> <span className="ml-1"> French </span></a>
								<a href="/" className="dropdown-item py-2"><i className="flag-icon flag-icon-de" title="de" id="de"></i> <span className="ml-1"> German </span></a>
								<a href="/" className="dropdown-item py-2"><i className="flag-icon flag-icon-pt" title="pt" id="pt"></i> <span className="ml-1"> Portuguese </span></a>
								<a href="/" className="dropdown-item py-2"><i className="flag-icon flag-icon-es" title="es" id="es"></i> <span className="ml-1"> Spanish </span></a>
							</div>
						</li> */}
						{/* <li className="nav-item dropdown nav-apps">
							<a className="nav-link dropdown-toggle" href="/" id="appsDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-grid"><rect x="3" y="3" width="7" height="7"></rect><rect x="14" y="3" width="7" height="7"></rect><rect x="14" y="14" width="7" height="7"></rect><rect x="3" y="14" width="7" height="7"></rect></svg>
							</a>
							<div className="dropdown-menu" aria-labelledby="appsDropdown">
								<div className="dropdown-header d-flex align-items-center justify-content-between">
									<p className="mb-0 font-weight-medium">Web Apps</p>
									<a href="/" className="text-muted">Edit</a>
								</div>
								<div className="dropdown-body">
									<div className="d-flex align-items-center apps">
										<a href="pages/apps/chat.html"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-message-square icon-lg"><path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path></svg><p>Chat</p></a>
										<a href="pages/apps/calendar.html"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-calendar icon-lg"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg><p>Calendar</p></a>
										<a href="pages/email/inbox.html"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-mail icon-lg"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg><p>Email</p></a>
										<a href="pages/general/profile.html"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-instagram icon-lg"><rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect><path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path><line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line></svg><p>Profile</p></a>
									</div>
								</div>
								<div className="dropdown-footer d-flex align-items-center justify-content-center">
									<a href="/">View all</a>
								</div>
							</div>
						</li> */}
						<li className="nav-item dropdown nav-messages">
							<a className="nav-link dropdown-toggle" href="/" id="messageDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								<FeatherIcon icon="mail" />
							</a>
							<div className="dropdown-menu" aria-labelledby="messageDropdown">
								<div className="dropdown-header d-flex align-items-center justify-content-between">
									<p className="mb-0 font-weight-medium">9 New Messages</p>
									<a href="/" className="text-muted">Clear all</a>
								</div>
								<div className="dropdown-body">
									<a href="/" className="dropdown-item">
										<div className="figure">
											<img src={require("images/faces/face2.jpg")} alt="userr" />
										</div>
										<div className="content">
											<div className="d-flex justify-content-between align-items-center">
												<p>Leonardo Payne</p>
												<p className="sub-text text-muted">2 min ago</p>
											</div>
											<p className="sub-text text-muted">Project status</p>
										</div>
									</a>
									<a href="/" className="dropdown-item">
										<div className="figure">
											<img src={require("images/faces/face3.jpg")} alt="userr" />
										</div>
										<div className="content">
											<div className="d-flex justify-content-between align-items-center">
												<p>Carl Henson</p>
												<p className="sub-text text-muted">30 min ago</p>
											</div>
											<p className="sub-text text-muted">Client meeting</p>
										</div>
									</a>
									<a href="/" className="dropdown-item">
										<div className="figure">
											<img src={require("images/faces/face4.jpg")} alt="userr" />
										</div>
										<div className="content">
											<div className="d-flex justify-content-between align-items-center">
												<p>Jensen Combs</p>
												<p className="sub-text text-muted">1 hrs ago</p>
											</div>
											<p className="sub-text text-muted">Project updates</p>
										</div>
									</a>
									<a href="/" className="dropdown-item">
										<div className="figure">
											<img src={require("images/faces/face5.jpg")} alt="userr" />
										</div>
										<div className="content">
											<div className="d-flex justify-content-between align-items-center">
												<p>Amiah Burton</p>
												<p className="sub-text text-muted">2 hrs ago</p>
											</div>
											<p className="sub-text text-muted">Project deadline</p>
										</div>
									</a>
									<a href="/" className="dropdown-item">
										<div className="figure">
											<img src={require("images/faces/face6.jpg")} alt="userr" />
										</div>
										<div className="content">
											<div className="d-flex justify-content-between align-items-center">
												<p>Yaretzi Mayo</p>
												<p className="sub-text text-muted">5 hr ago</p>
											</div>
											<p className="sub-text text-muted">New record</p>
										</div>
									</a>
								</div>
								<div className="dropdown-footer d-flex align-items-center justify-content-center">
									<a href="/">View all</a>
								</div>
							</div>
						</li>
						<li className="nav-item dropdown nav-notifications">
							<a className="nav-link dropdown-toggle" href="/" id="notificationDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								<FeatherIcon icon="bell" />
								<div className="indicator">
									<div className="circle"></div>
								</div>
							</a>
							<div className="dropdown-menu" aria-labelledby="notificationDropdown">
								<div className="dropdown-header d-flex align-items-center justify-content-between">
									<p className="mb-0 font-weight-medium">6 New Notifications</p>
									<a href="/" className="text-muted">Clear all</a>
								</div>
								<div className="dropdown-body">
									<a href="/" className="dropdown-item">
										<div className="icon">
											<FeatherIcon icon="user-plus" />
										</div>
										<div className="content">
											<p>New customer registered</p>
											<p className="sub-text text-muted">2 sec ago</p>
										</div>
									</a>
								</div>
								<div className="dropdown-footer d-flex align-items-center justify-content-center">
									<a href="/">View all</a>
								</div>
							</div>
						</li>
						<li className={`nav-item dropdown nav-profile ${showProfileDropdown ? 'show' : ''}`}>
							<a
								className="nav-link dropdown-toggle"
								href="/"
								id="profileDropdown"
								role="button"
								data-toggle="dropdown"
								aria-haspopup="true"
								aria-expanded="false"
								onClick={e => {
									e.preventDefault();
									return toggleProfileDropdown();
								}}
							>
								<img src={require("images/faces/face1.jpg")} alt="profile" />
							</a>
							<div className={`dropdown-menu ${showProfileDropdown ? 'show' : ''}`} aria-labelledby="profileDropdown">
								<div className="dropdown-header d-flex flex-column align-items-center">
									<div className="figure mb-3">
										<img src={require("images/faces/face1.jpg")} alt="" />
									</div>
									<div className="info text-center">
										<p className="name font-weight-bold mb-0">{user.first_name} {user.last_name}</p>
										<p className="email text-muted mb-3">{user.role.label}</p>
									</div>
								</div>
								<div className="dropdown-body">
									<ul className="profile-nav p-0 pt-3">
										<li className="nav-item">
											<a href="pages/general/profile.html" className="nav-link">
												<FeatherIcon icon="user" />
												<span>Profile</span>
											</a>
										</li>
										<li className="nav-item">
											<a href="/profile" className="nav-link">
												<FeatherIcon icon="edit" />
												<span>Edit Profile</span>
											</a>
										</li>
										<li className="nav-item">
											<a href="/auth/login" className="nav-link">
												<FeatherIcon icon="log-out" />
												<span>Log Out</span>
											</a>
										</li>
									</ul>
								</div>
							</div>
						</li>
					</ul>
					<button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="horizontal-menu-toggle">
						<FeatherIcon icon="menu" />
					</button>
				</div>
			</Container>
		</nav>
	);
};

const BottomNav = props => {
	const { location } = window;
	const { pathname } = location;
	const [ activeNavItem, setActiveNatItem ] = useState('');

	useEffect(() => {
		const arr = pathname.split('/');
		setActiveNatItem(arr[1]);
	}, [ pathname, setActiveNatItem ]);

	return (
		<nav className="bottom-navbar">
			<Container>
				<ul className="nav page-navigation">
					<li className={`nav-item ${activeNavItem === 'dashboard' ? 'active' : ''}`}>
						<a className="nav-link" href="/">
							<FeatherIcon icon="box" classes="link-icon" />
							<span className="menu-title">Dashboard</span>
						</a>
					</li>
					<li className={`nav-item ${activeNavItem === 'vehicles' ? 'active' : ''}`}>
						<a href="/vehicles" className="nav-link">
							<FeatherIcon icon="database" classes="link-icon" />
							<span className="menu-title">Vehicles</span>
							<i className="link-arrow"></i>
						</a>
						<div className="submenu">
							<ul className="submenu-item">
								{/* <li className="category-heading">Vehicles</li> */}
								<li className="nav-item"><a className="nav-link" href="/vehicles/add">Add Vehicle</a></li>
								<li className="nav-item"><a className="nav-link" href="/vehicles">Manage</a></li>
								{/* <li className="category-heading">Other</li>
								<li className="nav-item"><a className="nav-link" href="/vehicles/makes">Manage Makes</a></li>
								<li className="nav-item"><a className="nav-link" href="/vehicles/models">Manage Models</a></li>
								<li className="nav-item"><a className="nav-link" href="/vehicles/tags">Manage Tags</a></li> */}
							</ul>
						</div>
					</li>
					<li className={`nav-item ${activeNavItem === 'users' ? 'active' : ''}`}>
						<a href="/users" className="nav-link">
							<FeatherIcon icon="users" classes="link-icon" />
							<span className="menu-title">Users</span>
							<i className="link-arrow" />
						</a>
						<div className="submenu">
							<ul className="submenu-item">
								<li className="nav-item"><a className="nav-link" href="/users/roles">Manage Roles</a></li>
								<li className="nav-item"><a className="nav-link" href="/users">Manage Users</a></li>
							</ul>
						</div>
					</li>
					<li className={`nav-item ${activeNavItem === 'finance-applications' ? 'active' : ''}`}>
						<a href="/finance-applications" className="nav-link">
							<FeatherIcon icon="layers" classes="link-icon" />
							<span className="menu-title">Finance Applications</span>
						</a>
					</li>
					<li className={`nav-item ${activeNavItem === 'service-bookings' ? 'active' : ''}`}>
						<a href="/service-bookings" className="nav-link">
							<FeatherIcon icon="paperclip" classes="link-icon" />
							<span className="menu-title">Service Bookings</span>
						</a>
					</li>
					<li className={`nav-item ${activeNavItem === 'customers' ? 'active' : ''}`}>
						<a href="/customers" className="nav-link">
							<FeatherIcon icon="users" classes="link-icon" />
							<span className="menu-title">Customers</span>
						</a>
					</li>
					<li className="nav-item">
						<a href="https://www.mujimotorgroup.co.za" target="_blank" rel="noreferrer noopener" className="nav-link">
							<FeatherIcon icon="globe" classes="link-icon" />
							<span className="menu-title">Websites</span>
							<i className="link-arrow" />
						</a>
						<div className="submenu">
							<ul className="submenu-item">
								<li className="nav-item"><a href="https://www.mujivw.co.za" target="_blank" rel="noreferrer noopener" className="nav-link">MUJI Motor Group</a></li>
								<li className="nav-item"><a href="https://www.mujimotorgroup.co.za" target="_blank" rel="noreferrer noopener" className="nav-link">MUJI VW</a></li>
							</ul>
						</div>
					</li>
					<li className="nav-item">
						<a href="/documentation" className="nav-link">
							<FeatherIcon icon="hash" classes="link-icon" />
							<span className="menu-title">Documentation</span>
						</a>
					</li>
				</ul>
			</Container>
		</nav>
	);
};

const Header = props => {
	return (
		<div className="horizontal-menu">
			<TopNav {...props} />
			<BottomNav {...props} />
		</div>
	);
};

Header.propTypes = {
	active: PropTypes.string,
};

Header.defaultProps = {
	active: 'dashboard',
};

export default Header;
