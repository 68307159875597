import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { Layout } from 'containers';
import { EditVehicleWizard, Breadcrumb, Loader } from 'components';
import { VehiclesProvider } from 'providers';

const EditVehicle = props => {
    const { match } = props;
    const [ pageLoading, setPageLoading ] = useState(true);
    const [ activeTab, setActiveTab ] = useState(0);
    const [ vehicle, setVehicle ] = useState({});
    const [ updatedData, setUpdatedData ] = useState({});

	useEffect(() => {
		const fetchData = async () => {
            const { params } = match;
            const { id } = params;
            if (id) {
                const vehicle = await VehiclesProvider.get(id);
                if (vehicle) setVehicle(vehicle);
                return true;
            }
            return window.location = '/vehicles';
		};
		fetchData();
    }, [ match, setVehicle ]);

    const handleSaveProceed = (step, key = null, data = null) => {
        let obj = updatedData;
        if (key && data) {
            obj[key] = data;
            setUpdatedData(obj);
        }
        if (step === 4) {
            const postData = {
                model_id: obj.model_id,
                registration_year: obj.registration_year,
                registration_number: obj.registration_number,
                category_id: obj.model_id,
                price: obj.model_id,
                current_mileage: obj.model_id,
                date_acquired: obj.model_id,
                description: obj.model_id,
                name: obj.model_id,
                owner: obj.model_id,
                colour: obj.model_id,
                vin_number: obj.model_id,
                permakey: obj.model_id,
                images: obj.model_id,
                company_id: obj.model_id,
                type_id: obj.model_id,
                priority: obj.model_id,
                video_link: obj.model_id,
                vehicle_status: obj.model_id,
                stock_number: obj.model_id,
                regular_price: obj.model_id,
                label: obj.model_id,
                city_mpg: obj.model_id,
                highway_mpg: obj.model_id,
                interior_colour: obj.model_id,
                tax_label: obj.model_id,
                fuel_type: obj.model_id,
                fuel_economy: obj.model_id,
                body_style: obj.model_id,
                location: obj.model_id,
                brochure_link: obj.model_id,
                main_image: obj.model_id,
                drivetrain: obj.model_id,
                engine: obj.model_id,
                transmission: obj.model_id,
                trim: obj.model_id,
                status: obj.model_id,
                archived: obj.model_id,
                created: obj.model_id,
                updated: obj.model_id,
            };
            return console.log(postData);
            return console.log(vehicle.id, obj);
        }
        return setActiveTab(step + 1);
    };

    return (
        <Layout>
			{pageLoading && <Loader />}
            <Breadcrumb title="Edit Vehicle" />
            {vehicle.id &&
            <Row>
                <Col md={12} className="stretch-card">
                    <Card>
                        <CardBody>
                            <Row>
                                <Col lg={12}>
                                    <h4 className="card-title">{vehicle.name}</h4>
                                    <div role="application" className="wizard clearfix vertical">
                                        <div className="steps clearfix">
                                            <ul role="tablist">
                                                <li role="tab" className={`first ${activeTab === 0 ? 'current' : 'done'}`} aria-disabled={false} aria-selected={activeTab === 0}>
                                                    <a id="wizardVertical-t-0" href="#wizardVertical-h-0" aria-controls="wizardVertical-p-0"><span className="current-info audible">current step: </span><span className="number">1.</span> Vehicle Attributes</a>
                                                </li>
                                                <li role="tab" className={`${activeTab === 1 ? 'current' : ''} ${activeTab < 1 ? 'disabled' : ''} ${activeTab > 1 ? 'done' : ''}`} aria-disabled={activeTab < 1} aria-selected={activeTab === 1}>
                                                    <a id="wizardVertical-t-1" href="#wizardVertical-h-1" aria-controls="wizardVertical-p-1"><span className="number">2.</span> Vehicle Images</a>
                                                </li>
                                                <li role="tab" className={`${activeTab === 2 ? 'current' : ''} ${activeTab < 2 ? 'disabled' : ''} ${activeTab > 2 ? 'done' : ''}`} aria-disabled={activeTab < 2} aria-selected={activeTab === 2}>
                                                    <a id="wizardVertical-t-2" href="#wizardVertical-h-2" aria-controls="wizardVertical-p-2"><span className="number">3.</span> Vehicle PDF Brochure</a>
                                                </li>
                                                <li role="tab" className={`${activeTab === 3 ? 'current' : ''} ${activeTab < 3 ? 'disabled' : ''} ${activeTab > 3 ? 'done' : ''}`} aria-disabled={activeTab < 3} aria-selected={activeTab === 3}>
                                                    <a id="wizardVertical-t-3" href="#wizardVertical-h-3" aria-controls="wizardVertical-p-3"><span className="number">4.</span> Vehicle Location</a>
                                                </li>
                                                <li role="tab" className={`${activeTab === 4 ? 'current' : 'disabled'} last`} aria-disabled={activeTab < 4} aria-selected={activeTab === 4}>
                                                    <a id="wizardVertical-t-4" href="#wizardVertical-h-4" aria-controls="wizardVertical-p-4"><span className="number">5.</span> Additional Information</a>
                                                </li>
                                            </ul>
                                        </div>
                                        {activeTab === 0 &&
                                        <EditVehicleWizard.VehicleAttributes
                                            handleSaveProceed={handleSaveProceed}
                                            activeTab={activeTab}
                                            setActiveTab={setActiveTab}
                                            setPageLoading={setPageLoading}
                                            {...vehicle}
                                        />}
                                        {activeTab === 1 &&
                                        <EditVehicleWizard.VehicleImages
                                            handleSaveProceed={handleSaveProceed}
                                            activeTab={activeTab}
                                            setActiveTab={setActiveTab}
                                            setPageLoading={setPageLoading}
                                            {...vehicle}
                                        />}
                                        {activeTab === 2 &&
                                        <EditVehicleWizard.VehiclePdfBrochure
                                            handleSaveProceed={handleSaveProceed}
                                            activeTab={activeTab}
                                            setActiveTab={setActiveTab}
                                            setPageLoading={setPageLoading}
                                            {...vehicle}
                                        />}
                                        {activeTab === 3 &&
                                        <EditVehicleWizard.VehicleLocation
                                            handleSaveProceed={handleSaveProceed}
                                            activeTab={activeTab}
                                            setActiveTab={setActiveTab}
                                            {...vehicle}
                                        />}
                                        {activeTab === 4 &&
                                        <EditVehicleWizard.AdditionalInformation
                                            handleSaveProceed={handleSaveProceed}
                                            activeTab={activeTab}
                                            setActiveTab={setActiveTab}
                                            setPageLoading={setPageLoading}
                                            {...vehicle}
                                        />}
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>}
        </Layout>
    );
};

export default EditVehicle;
