import React from 'react';
import PropTypes from 'prop-types';

const ProgressBar = props => {
    const {
        wrapperClass,
        barLevel1,
        barLevel2,
        barLevel3,
        labelLeft,
        labelRight,
    } = props;
    return (
        <div className={`os-progress-bar ${wrapperClass}`}>
            <div className="bar-labels">
                <div className="bar-label-left"><span className="bigger">{labelLeft}</span></div>
                <div className="bar-label-right"><span className="info">{labelRight}</span></div>
            </div>
            <div className="bar-level-1" style={{ width: barLevel1 }}>
                <div className="bar-level-2" style={{ width: barLevel2 }}>
                    <div className="bar-level-3" style={{ width: barLevel3 }} />
                </div>
            </div>
        </div>
    );
};

ProgressBar.propTypes = {
    wrapperClass: PropTypes.string,
    barLevel1: PropTypes.string,
    barLevel2: PropTypes.string,
    barLevel3: PropTypes.string,
    labelLeft: PropTypes.string,
    labelRight: PropTypes.string,
};

ProgressBar.defaultProps = {
    wrapperClass: 'primary',
    barLevel1: '100%',
    barLevel2: '0%',
    barLevel3: '0%',
    labelLeft: 'MailGun',
    labelRight: '0 Tickets / 0 Pending',
};

export default ProgressBar;
