import React from 'react';
import useForm from 'react-hook-form';
import { Col, Row } from 'reactstrap';

const VehicleLocation = props => {
    const {
        activeTab,
        handleSaveProceed,
        setActiveTab,
    } = props;
    const { register, handleSubmit, errors } = useForm();

    const onSubmit = data => {
        handleSaveProceed(activeTab, 'location',  data);
    };

    return (
        <>
            <div className="content clearfix">
                <h2 id="wizardVertical-h-3" tabIndex="-1" className="title current">Vehicle Location</h2>
                <section id="wizardVertical-p-3" role="tabpanel" aria-labelledby="wizardVertical-h-3" className="body current" aria-hidden="false">
                    <h4>Vehicle Location</h4>
                    <p className="card-description">
                        Enter vehicle location or use map to find location below.
                    </p>
                    <form id="signupForm" className="cmxform" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col sm={12}>
                                <div className={`form-group ${errors.location ? 'has-danger' : ''}`}>
                                    <label htmlFor="location">Location</label>
                                    <input
                                        className={`form-control ${errors.location ? 'form-control-danger' : ''}`}
                                        name="location"
                                        id="location"
                                        placeholder="Enter vehicle location"
                                        ref={register({ required: false })}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </form>
                </section>
            </div>
            <div className="actions clearfix">
                <ul role="menu" aria-label="Pagination">
                    <li className={activeTab === 0 ? 'disabled' : ''} aria-disabled={activeTab === 0}>
                        <a
                            href="#previous"
                            role="menuitem"
                            onClick={e => {
                                e.preventDefault();
                                if (activeTab > 0) {
                                    return setActiveTab(activeTab - 1);
                                }
                            }}
                        >Previous</a>
                    </li>
                    {activeTab < 2 &&
                    <li aria-hidden="false" aria-disabled="false">
                        <button
                            type="submit"
                            form="signupForm"
                            className="btn btn-primary"
                            onClick={handleSubmit(onSubmit)}
                        >
                            Next
                        </button>
                    </li>}
                    {activeTab === 2 &&
                    <li aria-hidden="true">
                        <a href="#finish" role="menuitem">Finish</a>
                    </li>}
                </ul>
            </div>
        </>
    );
};

export default VehicleLocation;
