import React from 'react';
import { Header, Footer } from '../containers';

const Layout = props => {
    const { header, footer } = props;
    return (
        <div className="main-wrapper">
            <Header {...header} />
            <div className="page-wrapper">
                <div className="page-content">
                    {props.children}
                </div>
                <Footer {...footer} />
            </div>
        </div>
    );
};

export default Layout;