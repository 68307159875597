import axios from 'axios';
import config from '../config';
import SessionProvider from './SessionProvider';

const baseurl = config.api[config.stage].baseurl;
const session = SessionProvider.get();
const token = SessionProvider.getToken();
let headers = {};

if (session.isValid) {
  headers = {
    'Authorization': `Bearer ${token}`,
  }
}

class AuthProvider {
  static async login(data) {
    return await axios({
      mode: 'no-cors',
      method: 'POST',
      url: `${baseurl}auth/login`,
      crossdomain: true,
      headers,
      data,
    })
      .then((json) => json.data)
      .then(res => res)
      .catch((err) => {
        if (err.response) return err.response.data;
        return err;
      });
  };
}

export default AuthProvider;
